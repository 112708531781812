import { Modal } from '@models';
import { Controller as OrganismController } from '@organisms';
import { Col, Form, Row } from 'antd';
import { useEffect } from 'react';

import { ModelStepBaseComponentProp } from '../ModalDetail';
import { BaseContent } from './BaseContent.component';

const BASE_PATH =
  (process.env.REACT_APP_BASE_URL || '').indexOf('apidev') === -1
    ? 'https://play.queimadiaria.com'
    : 'https://dev.queimadiaria.com';

export const HighlightContent = ({
  form,
}: ModelStepBaseComponentProp<Modal.IDefaultContent>) => {
  useEffect(() => {
    form.setValue('urlLocalShow', `${BASE_PATH}/home`, {
      shouldValidate: true,
    });
  }, []);
  return (
    <Form layout='vertical'>
      <Row>
        <Col span={24}>
          <OrganismController
            {...form.register('urlLocalShow')}
            control={form.control}
            label='Local para exibição do modal'
            placeholder='Insira a URL onde o modal será exibido'
            autoComplete='off'
            showCount
            value={`${BASE_PATH}/home`}
            disabled
            prefix='https://'
            maxLength={110}
          />
        </Col>
      </Row>

      <BaseContent form={form} />
    </Form>
  );
};
