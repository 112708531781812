import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { FunctionalityFormData } from '../../types/functionalityTypings';
import { useTryToRequestFunctionalityPage } from './useTryToRequestFunctionalityPage';
import { useTryToRequestUpdateFunctionalityPage } from './useTryToRequestUpdateFunctionalityPage';

interface useRequestFunctionalityPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    slides: {
      title: string;
      slideTitle: string;
      web: string;
      mobile: string;
      description: string;
      buttonTitle: string;
      buttonLink: string;
      isTarget: boolean;
    }[];
  }>;
}

export function useRequestFunctionalityPage({
  titlePage,
  setTitlePage,
  isUpdate,
  setIsUpdate,
  setValue,
}: useRequestFunctionalityPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestFunctionality } = useTryToRequestFunctionalityPage();
  const { isLoadingFunctionalityUpdate, tryToRequestUpdateFunctionalityPage } =
    useTryToRequestUpdateFunctionalityPage();

  const handleUpdateFunctionalityPage = useCallback(
    async (e: Omit<FunctionalityFormData, 'name'>) => {
      await tryToRequestUpdateFunctionalityPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [titlePage, tryToRequestUpdateFunctionalityPage]
  );

  const handleRequestFunctionalityPage = useCallback(async () => {
    await tryToRequestFunctionality(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      response.slides.forEach((slide: any, index) => {
        const slidePrefix = `slides[${index}]`;

        Object.keys(slide).forEach(key => {
          const field = `${slidePrefix}.${key}`;
          const value = slide[key];
          setValue(field as any, value);
        });
      });
      setTitlePage(response.name);
    });
  }, [tryToRequestFunctionality, setValue, setTitlePage]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestFunctionalityPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { isLoadingFunctionalityUpdate, handleUpdateFunctionalityPage };
}
