export const Flame = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='48' height='48' rx='24' fill='#FFF6EC' />
    <path
      d='M26.5845 24.075C26.8423 23.6516 27.1088 23.2139 27.372 22.7625C28.253 21.2523 28.4146 19.7306 27.8526 18.2396C26.7187 15.2313 22.9281 13.3983 22.0506 13.1059L21.4335 12.9L20.4704 14.8266L20.9352 15.2063C20.9404 15.2104 21.4566 15.6615 21.491 16.3453C21.5204 16.9291 21.2004 17.5599 20.5401 18.2203C20.0816 18.6788 19.5783 19.1222 19.0455 19.5915C16.8036 21.5658 14.2627 23.8041 14.2627 28.0385C14.2627 28.0966 14.2632 28.1545 14.2643 28.2119C14.2806 29.1463 14.4813 30.0683 14.8549 30.925C15.2285 31.7816 15.7676 32.556 16.4414 33.2036C17.7943 34.5196 19.6083 35.254 21.4956 35.25H25.8128L25.2799 34.1685C23.1146 29.7739 24.6459 27.259 26.5845 24.075ZM21.4956 33.75C18.3925 33.75 15.8214 31.2531 15.7641 28.1841C15.7632 28.1358 15.7627 28.0873 15.7627 28.0385C15.7627 24.4817 17.9356 22.5676 20.037 20.7166C20.5639 20.2526 21.1087 19.7726 21.6007 19.2805C22.5736 18.3077 23.0407 17.2946 22.9891 16.2696C22.9666 15.8352 22.8513 15.4107 22.651 15.0246C23.956 15.7376 25.8161 17.0896 26.4491 18.7689C26.8496 19.8313 26.7277 20.8904 26.0764 22.0069C25.8202 22.4461 25.5574 22.8777 25.3033 23.2951C23.4433 26.3499 21.6788 29.2474 23.454 33.75H21.4956Z'
      fill='#FF4F00'
    />
    <path
      d='M33.9491 27.9904C33.9366 27.9409 33.9236 27.8911 33.91 27.8412C33.3688 25.8568 30.373 23.3054 30.0332 23.021L29.3508 22.45L28.9033 23.2191C27.8837 24.9723 27.0086 26.5713 26.6271 28.3853C26.1922 30.4541 26.485 32.5567 27.5225 34.8133L27.7231 35.25H28.233C29.1311 35.2526 30.0179 35.049 30.8249 34.6548C31.6319 34.2607 32.3376 33.6865 32.8877 32.9766C33.4345 32.2822 33.8145 31.4715 33.9985 30.607C34.1825 29.7425 34.1656 28.8474 33.9491 27.9904ZM31.7047 32.0537C31.3412 32.5231 30.8859 32.9135 30.3666 33.2012C29.8473 33.4889 29.2748 33.6678 28.6841 33.7272C27.2056 30.1693 28.078 27.7443 29.7449 24.7687C30.8531 25.7997 32.2037 27.2854 32.4628 28.2356C32.4737 28.2757 32.4842 28.3156 32.4942 28.3553C32.6545 28.9911 32.6666 29.6552 32.5298 30.2964C32.3929 30.9376 32.1106 31.5388 31.7047 32.0537Z'
      fill='#FF4F00'
    />
  </svg>
);
