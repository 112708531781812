import { PageHeader } from '@organisms';
import { coupons } from '@service/api';
import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { ICoupon } from 'src/models/Coupon.model';

import { LayoutConfirmationFooter } from '../../molecules/LayoutConfirmationFooter/LayoutConfirmationFooter.molecule';
import { CouponForm } from './components/CouponForm/CouponForm.component';
import { useCoupon } from './hooks/useCoupon';

export function EditCouponPage() {
  const { id }: { id: string } = useParams();
  const {
    handleCancel,
    dispatchSubmit,
    onSaveCoupon,
    formRef,
    isLoading,
    setIsLoading,
  } = useCoupon();

  const [coupon, setCoupon] = useState<ICoupon | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    coupons
      .getCouponById(Number(id))
      .then(couponData => setCoupon(couponData))
      .catch((error: Error) => {
        OpenNotification(true, 'Erro', error.message, undefined, () => {
          window.history.back();
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Spin spinning={isLoading}>
      <LayoutConfirmationFooter
        onCancel={handleCancel}
        onSave={dispatchSubmit}
        cancelLabelButton='Descartar'
        ableToSave
      >
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={`Formulário para edição do Cupom ${
            coupon ? coupon.code : '...'
          }`}
          subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
        />
        <CouponForm
          formRef={formRef}
          onFinish={onSaveCoupon}
          onLoading={setIsLoading}
          coupon={coupon}
          isEditing={true}
        />
      </LayoutConfirmationFooter>
    </Spin>
  );
}
