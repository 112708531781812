import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Layout,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import moment from 'moment';
import { ForwardedRef, useEffect, useMemo, useState } from 'react';

import {
  ICoupon,
  ProductSelectionEnum,
  PurchaseTypeAllowedEnum,
  TypeEnum,
} from 'src/models/Coupon.model';

import { useCouponForm } from '../../hooks/useCouponForm';
import { TFormData } from '../../types/formDataType';
import { FAQTooltip } from '../FAQTooltip/FAQTooltip';
import { CouponFormSection } from './CouponFormSection.component';
import {
  Container,
  ErrorContent,
  FormCoupon,
  InputFormCoupon,
  RadioInput,
  RadioInputContainer,
  SidePanelContainer,
  SwitchInputContainer,
  TextAreaFormCoupon,
} from './styles';

type TAddCouponForm = {
  formRef: ForwardedRef<HTMLFormElement>;
  readonly?: boolean;
  isEditing?: boolean;
  coupon?: ICoupon;
  onLoading: (isLoading: boolean) => void;
  onFinish?: () => Promise<void> | void;
};

type TLimitDiscount = 'unlimited' | 'limited';

const LENGTH_MIN_CODE = 2;
const LENGTH_MAX_CODE = 20;

export function CouponForm({
  formRef,
  onFinish,
  onLoading,
  coupon,
  readonly = false,
  isEditing = false,
}: TAddCouponForm) {
  const {
    INFINITE_DISCOUNT_LIMIT,
    productOptions,
    isLoadingProductOptions,
    submitCreateCoupon,
    submitEditCoupon,
    handleDisplayError,
  } = useCouponForm();
  const [form] = FormCoupon.useForm();
  const [active, setActive] = useState<boolean>(true);
  const [typeDiscount, setTypeDiscount] = useState<TypeEnum>(TypeEnum.FIXED);
  const [limitDiscount, setLimitDiscount] =
    useState<TLimitDiscount>('unlimited');
  const [productSelection, setProductSelection] =
    useState<ProductSelectionEnum>(ProductSelectionEnum.ALL);
  const [errorsDisplay, setErrorsDisplay] = useState<string[]>([]);

  const initialValues = useMemo(() => {
    if (!coupon)
      return {
        typeDiscount: TypeEnum.FIXED,
        purchaseTypeAllowed: PurchaseTypeAllowedEnum.ALL,
        active: true,
      };
    return {
      ...coupon,
      allowedProducts:
        !!coupon.allowedProducts && coupon.allowedProducts?.length > 0
          ? coupon.allowedProducts
          : undefined,
      startDate: moment(coupon.startDate),
      endDate: moment(coupon.endDate),
      limitValue:
        coupon.maxUse < INFINITE_DISCOUNT_LIMIT ? coupon.maxUse : undefined,
      purchaseTypeAllowed:
        coupon.purchaseTypeAllowed || PurchaseTypeAllowedEnum.ALL,
      typeDiscount: coupon.discount.type,
      value: coupon.discount.value,
    };
  }, [coupon]);

  useEffect(() => {
    form.resetFields();
    if (coupon) {
      setTypeDiscount(coupon.discount.type);
      setLimitDiscount(
        coupon.maxUse < INFINITE_DISCOUNT_LIMIT ? 'limited' : 'unlimited'
      );
      setProductSelection(
        !!coupon.allowedProducts && coupon.allowedProducts.length > 0
          ? ProductSelectionEnum.CERTAIN_KITS
          : ProductSelectionEnum.ALL
      );
      setActive(coupon && coupon?.active ? coupon?.active : true);
    }
  }, [coupon]);

  const handleSubmit = async (data: unknown) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    try {
      onLoading(true);
      console.log(isEditing);
      if (isEditing && coupon) {
        console.log('edit');
        await submitEditCoupon(coupon.id, data as TFormData);
      } else {
        await submitCreateCoupon(data as TFormData);
      }
      !!onFinish && onFinish();
    } catch (err) {
      setErrorsDisplay([handleDisplayError(err)]);
    } finally {
      onLoading(false);
    }
  };

  useEffect(
    () => onLoading(isLoadingProductOptions),
    [isLoadingProductOptions]
  );

  return (
    <>
      {errorsDisplay.length > 0 && (
        <ErrorContent>
          <CloseCircleFilled />
          <div>
            <p>
              Há {errorsDisplay.length} erro{errorsDisplay.length !== 1 && 's'}{' '}
              com este cupom:
            </p>

            {errorsDisplay.map(errorMsg => (
              <span key={errorMsg}>-{errorMsg}</span>
            ))}
          </div>
          <Button
            style={{ marginLeft: 'auto', border: 'none' }}
            onClick={() => setErrorsDisplay([])}
          >
            <CloseOutlined />
          </Button>
        </ErrorContent>
      )}
      <Container style={{ overflow: 'hidden' }}>
        <Layout.Content style={{ backgroundColor: 'transparent' }}>
          <FormCoupon
            form={form}
            disabled={readonly}
            layout='vertical'
            ref={formRef as any}
            onFinish={handleSubmit}
            initialValues={initialValues}
            onFinishFailed={({ errorFields }) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setErrorsDisplay(
                errorFields.map(({ errors }) => errors.join(', '))
              );
            }}
          >
            <CouponFormSection title='Dados do Cupom'>
              <FormCoupon.Item
                name='code'
                label='Código do Cupom'
                required
                tooltip='O código deve ser único'
                rules={[
                  { required: true },
                  {
                    pattern: /^[^\s]*$/,
                    message: 'O código não pode ter espaço, utilize "_"',
                  },
                  {
                    min: LENGTH_MIN_CODE,
                    message: `O código deve ter no mínimo ${LENGTH_MIN_CODE} caracteres.`,
                  },
                  {
                    max: LENGTH_MAX_CODE,
                    message: `O código deve ter no máximo ${LENGTH_MAX_CODE} caracteres.`,
                  },
                ]}
              >
                <InputFormCoupon
                  disabled={readonly || isEditing}
                  style={{ textTransform: 'uppercase' }}
                />
              </FormCoupon.Item>
              <FormCoupon.Item
                name='description'
                label='Descrição do Cupom'
                required
                tooltip='Explique o objetivo do cupom'
                rules={[{ required: true }]}
              >
                <TextAreaFormCoupon placeholder='Detalhe o cupom.' />
              </FormCoupon.Item>
            </CouponFormSection>

            <CouponFormSection title='Período' direction='row'>
              <FormCoupon.Item
                name='startDate'
                label='Data de Início'
                required
                style={{ flex: 1 }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format='DD/MM/YYYY HH:mm'
                  style={{
                    width: '100%',
                    background: 'rgba(20, 20, 20, 1)',
                    border: '1px solid rgba(66, 66, 66, 1)',
                    borderRadius: 6,
                  }}
                />
              </FormCoupon.Item>
              <FormCoupon.Item
                label='Data de Expiração'
                required
                style={{ flex: 1 }}
                name='endDate'
                rules={[{ required: true }]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format='DD/MM/YYYY HH:mm'
                  style={{
                    width: '100%',
                    background: 'rgba(20, 20, 20, 1)',
                    border: '1px solid rgba(66, 66, 66, 1)',
                    borderRadius: 6,
                  }}
                />
              </FormCoupon.Item>
            </CouponFormSection>

            <CouponFormSection title='Tipo de desconto (Escolher apenas 1)'>
              <FormCoupon.Item name='typeDiscount' rules={[{ required: true }]}>
                <Radio.Group
                  onChange={e => {
                    form.resetFields(['value']);
                    setTypeDiscount(e.target.value);
                  }}
                >
                  <RadioInputContainer>
                    <RadioInput value={TypeEnum.FIXED}>
                      Valor Fixo de desconto
                      <FAQTooltip message='Use virgula para separar os centavos (Não use ponto)' />
                    </RadioInput>
                    <FormCoupon.Item
                      name={
                        typeDiscount === TypeEnum.FIXED ? 'value' : undefined
                      }
                      rules={[
                        {
                          required: typeDiscount === TypeEnum.FIXED,
                          message: 'Por favor, insira o valor do cupom',
                        },
                      ]}
                    >
                      <InputFormCoupon
                        required={typeDiscount === TypeEnum.FIXED}
                        prefix='R$'
                        placeholder='0,00'
                        type='number'
                        disabled={readonly || typeDiscount !== TypeEnum.FIXED}
                      />
                    </FormCoupon.Item>
                  </RadioInputContainer>
                  <RadioInputContainer>
                    <RadioInput value={TypeEnum.PERCENT}>
                      Percentual de desconto{' '}
                      <FAQTooltip message='Use apenas números inteiros' />
                    </RadioInput>
                    <FormCoupon.Item
                      name={
                        typeDiscount === TypeEnum.PERCENT ? 'value' : undefined
                      }
                      rules={[
                        {
                          required: typeDiscount === TypeEnum.PERCENT,
                          message: 'Por favor, insira a porcentagem desejada',
                        },
                        {
                          pattern: /^-?\d+$/,
                          message: 'Por favor, insira um número inteiro',
                        },
                      ]}
                    >
                      <InputFormCoupon
                        required={typeDiscount === TypeEnum.PERCENT}
                        suffix='%'
                        placeholder='0'
                        type='number'
                        disabled={readonly || typeDiscount !== TypeEnum.PERCENT}
                      />
                    </FormCoupon.Item>
                  </RadioInputContainer>
                </Radio.Group>
              </FormCoupon.Item>
            </CouponFormSection>

            <CouponFormSection title='Limites de uso (Escolher apenas 1)'>
              <Radio.Group
                defaultValue='unlimited'
                value={limitDiscount}
                onChange={e => {
                  form.resetFields(['limitValue']);
                  setLimitDiscount(e.target.value);
                }}
              >
                <RadioInputContainer>
                  <RadioInput value='unlimited'>
                    Sem limite geral de uso
                  </RadioInput>
                </RadioInputContainer>
                <RadioInputContainer>
                  <RadioInput value='limited'>
                    Limite de uso geral{' '}
                    <FAQTooltip message='Use apenas números inteiros' />
                  </RadioInput>

                  <FormCoupon.Item
                    name={
                      limitDiscount === 'unlimited' ? undefined : 'limitValue'
                    }
                    rules={[
                      {
                        required: limitDiscount === 'limited',
                        message: 'Por favor, insira o limite Geral',
                      },
                      {
                        pattern: /^-?\d+$/,
                        message: 'Por favor, insira um número inteiro',
                      },
                    ]}
                  >
                    <InputFormCoupon
                      suffix='unid.'
                      placeholder='0'
                      type='number'
                      disabled={readonly || limitDiscount !== 'limited'}
                    />
                  </FormCoupon.Item>
                </RadioInputContainer>
              </Radio.Group>
            </CouponFormSection>

            <CouponFormSection title='Condições de uso (Escolher apenas 1)'>
              <FormCoupon.Item
                required
                style={{ flex: 1 }}
                name='purchaseTypeAllowed'
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={PurchaseTypeAllowedEnum.ALL}>
                    Sem restrição
                  </Radio>
                  <Radio value={PurchaseTypeAllowedEnum.FIRST}>
                    Apenas na primeira compra
                  </Radio>
                  <Radio value={PurchaseTypeAllowedEnum.RECURRENT}>
                    Apenas para Ex-Clientes MC
                  </Radio>
                </Radio.Group>
              </FormCoupon.Item>
            </CouponFormSection>

            <CouponFormSection title='Seleção de Produtos'>
              <RadioInputContainer>
                <Radio.Group
                  defaultValue={ProductSelectionEnum.ALL}
                  value={productSelection}
                  onChange={e => {
                    setProductSelection(e.target.value);
                    form.setFieldValue('allowedProducts', undefined);
                  }}
                >
                  <Space direction='vertical'>
                    <RadioInput value={ProductSelectionEnum.ALL}>
                      Todos
                    </RadioInput>
                    <RadioInput value={ProductSelectionEnum.CERTAIN_KITS}>
                      Apenas para Kits determinados
                    </RadioInput>
                  </Space>
                </Radio.Group>
                <FormCoupon.Item
                  required
                  style={{ flex: 1 }}
                  name='allowedProducts'
                  rules={[
                    {
                      required: productSelection !== ProductSelectionEnum.ALL,
                      message:
                        'Por favor, selecione ao menos um kit de produto',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    disabled={
                      readonly || productSelection === ProductSelectionEnum.ALL
                    }
                    options={productOptions}
                  />
                </FormCoupon.Item>
              </RadioInputContainer>
            </CouponFormSection>
            <CouponFormSection title='Status do cupom'>
              <FormCoupon.Item name='active'>
                <SwitchInputContainer>
                  <span>inativo</span>
                  <Switch
                    checked={active}
                    onChange={value => {
                      setActive(value);
                      form.setFieldValue('active', value);
                    }}
                  ></Switch>
                  <span>ativo</span>
                </SwitchInputContainer>
              </FormCoupon.Item>
            </CouponFormSection>
          </FormCoupon>
        </Layout.Content>
        <SidePanelContainer offsetTop={20}>
          <Layout.Sider
            style={{
              backgroundColor: 'transparent',
              marginLeft: 16,
              flex: 1,
            }}
            width={480}
          >
            <CouponFormSection title='Regras'>
              <Typography
                style={{
                  color: 'rgba(255, 255, 255, 0.65)',
                }}
              >
                <b>Código do cupom:</b>
                {` `}É o cupom em si, deve ser cadastrado com letra maiúscula.
                Mínimo 2 caracteres e sem espaço;
                <br />
                <br />
                <b>Descrição:</b>
                {` `}Campo de uso interno da Queima Diária;
                <br />
                <br />
                <b>Percentual de desconto e Valor fixo:</b> Campo numérico que
                representar a opção escolhida. <br />
                <small>
                  Exemplo: Para 10%OFF, digitar 10. Para R$100 OFF, digitar 100.
                </small>
                <br />
                <br />
                <b>Limite de uso geral:</b>
                {` `}
                Definir o máximo de vezes que o cupom pode ser utilizado,
                independente do cliente.
              </Typography>
            </CouponFormSection>
          </Layout.Sider>
        </SidePanelContainer>
      </Container>
    </>
  );
}
