import { slices } from '@core/redux';
import { Modal } from '@models';
import { modals } from '@service/api';
import { CountResponse, TakeSkipFilter } from '@typings';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';

export const useModal = () => {
  const dispatch = useDispatch();

  const [modalList, setModalList] = useState<CountResponse<Modal.IModal>>();

  const [currentFilter, setCurrentFilter] = useState<TakeSkipFilter>();

  const getAllModals = async (props?: TakeSkipFilter) => {
    try {
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.skip || !props.take) {
        props = {
          ...props,
          skip: 0,
          take: 10,
        };
      }

      const response = await modals.getAllModal(props);
      setModalList(response);
    } catch (err) {
      console.log(err);
      OpenNotification(true, 'Falha ao carregar os programas do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };
  const deleteModalById = async (modal: Modal.IModal) => {
    try {
      dispatch(slices.layout.increaseLoading());

      await modals.deleteModalById(modal.id);
      getAllModals(currentFilter);
      OpenNotification(
        false,
        `Modal “${modal.advertisements[0].title}” excluído com sucesso!`
      );
    } catch (err) {
      console.log(err);
      OpenNotification(true, 'Falha ao excluir o Modal.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return {
    getAllModals,
    deleteModalById,
    setCurrentFilter,
    currentFilter,
    modalList,
  };
};
