import {
  MediaFormData,
  MediaPayload,
  MediaResponse,
} from '../types/mediaPageTypings';
import { uploadS3 } from './uploadS3';

export const mediaPayloadFunnelsPageFactory = async (
  formData: MediaFormData
) => {
  const factory: MediaPayload = {
    cancellationFunnelId: Number(formData.cancellationFunnelId),
    pageType: 'media',
    name: formData.name,
    content: {
      slides: await Promise.all(
        formData.slides.map(async slide => {
          return {
            title: slide.title,
            imageWeb:
              typeof slide.web === 'string'
                ? slide.web
                : await uploadS3(slide.web),
            imageMobile:
              typeof slide.mobile === 'string'
                ? slide.mobile
                : await uploadS3(slide.mobile),
            titleImage: slide.imageTitle,
            description: slide.description,
            numericInformative: slide.informativeNumeric,
            textInformative: slide.informativeText,
            buttonText: slide.buttonTitle,
            buttonLink: slide.buttonLink,
            isTargetBlank: slide.isTarget,
          };
        })
      ),
    },
  };

  return factory;
};

export const mediaResponseFactory = (response: MediaResponse) => {
  const body = {
    cancellationFunnelId: `${response.cancellationFunnelId}`,
    name: response.name,
    slides: response.content.slides.map(slide => ({
      title: slide.title,
      buttonLink: slide.buttonLink,
      buttonTitle: slide.buttonText,
      description: slide.description,
      imageTitle: slide.titleImage,
      informativeNumeric: slide.numericInformative,
      informativeText: slide.textInformative,
      isTarget: slide.isTargetBlank,
      mobile: slide.imageMobile,
      web: slide.imageWeb,
    })),
  };

  return body;
};
