import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { Success } from 'src/app/utils/statusCode';

import { clientApi } from '../api/clientApi';
import { AdyenTransactionResponse } from '../types/clientTypes';

const fetchAdyenCancelation = async (emailParamAdyenCancelation?: string) => {
  if (emailParamAdyenCancelation) {
    const response = await clientApi.getAdyenTableCancelation(
      emailParamAdyenCancelation
    );

    if ((response as AdyenTransactionResponse).status === Success) {
      return (response as AdyenTransactionResponse).data;
    } else {
      OpenNotification(
        true,
        'Erro ao carregar as transações',
        'Ocorreu um erro  ao carregar as transações, por favor tente novamente mais tarde.'
      );
    }
  } else {
    return null;
  }
};

export default function useCancelationAdyen(email?: string) {
  return useQuery(
    ['cancelationAdyen', email],
    () => fetchAdyenCancelation(email),
    {
      refetchOnWindowFocus: false,
    }
  );
}
