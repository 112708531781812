import { defineEnv } from '../utils/app/defineEnv';

export const env = {
  RUN_MOCKS: defineEnv('REACT_APP_RUN_MOCKS'),
  IS_DEVELOPMENT: defineEnv('NODE_ENV') === 'development',
  BASE_URL: defineEnv('REACT_APP_BASE_URL'),
  GAMEFICATION_URL: defineEnv('REACT_APP_GAMEFICATION_URL'),
  AUTH_ENCRYPT_KEY: defineEnv('REACT_APP_AUTH_ENCRYPT_KEY') as string,
  CHECKOUT: defineEnv('REACT_APP_CHECKOUT_URL'),
  ASSETS: defineEnv('REACT_APP_ASSETS'),
  FILE_API: defineEnv('REACT_APP_FILE_API'),
};
