import { Tabs } from 'antd';
import { FC } from 'react';

import {
  AdyenTransaction,
  ClientType,
} from 'src/features/clients/types/clientTypes';

import { CancelationTabsComponent } from './tables/CancelationTabsComponent';
import { RefundsTabsComponent } from './tables/RefundsTabsComponent';
import { TransactionsTabsComponent } from './tables/TransactionsTabsComponent';

export type TabsProps = {
  transactionsAdyen?: AdyenTransaction[];
  refundAdyen?: AdyenTransaction[];
  transactionsCancelation?: AdyenTransaction[];
  dados?: ClientType;
};

export const AdyenTransactionTabsComponent: FC<TabsProps> = ({
  transactionsAdyen,
  refundAdyen,
  transactionsCancelation,
  dados,
}) => {
  const { TabPane } = Tabs;

  return (
    <>
      <Tabs defaultActiveKey='1' style={{ display: 'block' }}>
        <TabPane tab='Aprovadas' key='1'>
          <TransactionsTabsComponent
            transactionsAdyen={transactionsAdyen}
            dados={dados}
          />
        </TabPane>
        <TabPane tab='Reembolsos' key='2'>
          <RefundsTabsComponent
            refundAdyen={refundAdyen}
            email={dados?.email ?? ''}
          />
        </TabPane>
        <TabPane tab='Recusadas' key='3'>
          <CancelationTabsComponent
            transactionsCancelation={transactionsCancelation}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
