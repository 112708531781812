import {
  DeleteOutlined,
  PlusCircleOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CheckboxController,
  Controller,
  InputNumberController,
  SelectController,
  TextEditController,
} from '@organisms';
import { Button, Col, Collapse, Divider, Form, Row, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import * as yup from 'yup';

import { LayoutFunnelPage } from 'src/features/Funnel/Components/LayoutFunnelPage';
import { TitleFunnelPage } from 'src/features/Funnel/Components/TitleFunnelPage';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';
import { useRequestOfferPage } from 'src/features/Funnel/hooks/Offer/useRequestOfferPage';
import { useRequestOfferProductSellGroup } from 'src/features/Funnel/hooks/Offer/useRequestOfferProductSellGroup';
import { useTryToRequestCreatingOfferFunnelPage } from 'src/features/Funnel/hooks/Offer/useTryToRequestCreatingOfferFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { OfferFormData } from 'src/features/Funnel/types/offerPageTypings';
import { currencyParser } from 'src/features/Funnel/utils/currencyParser';

const slidesValidationSchema = yup.object().shape({
  title: yup.string().required('O Titulo Principal é obrigatório'),
  offer: yup.string().required('A Oferta é obrigatória'),
  web: yup
    .mixed()
    .nullable()
    .required('A Versão Web é obrigatório')
    .test('is-filled', 'A Versão Web é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  mobile: yup
    .mixed()
    .nullable()
    .required('A Versão mobile é obrigatório')
    .test('is-filled', 'A Versão mobile é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  imageTitle: yup.string().required('O Título do lado da imagem é obrigatório'),
  subtitle: yup.string().required('O Subtítulo é obrigatório'),
  description: yup.string().required('A Descrição é obrigatório'),
  oldValue: yup.number().required('O Valor antigo é obrigatório'),
  finalValue: yup.string().nullable(),
  textValue: yup.string().required('O Texto em baixo do valor é obrigatório'),
  buttonTitle: yup.string().required('A Título do Botão é obrigatório'),
  buttonLink: yup.string().required('O Link do botão é obrigatório'),
  isTarget: yup.boolean().nullable(),
});

const valiationSchema = yup.object({
  slides: yup
    .array()
    .of(slidesValidationSchema)
    .required('Informe ao menso um slide'),
});

export const FunnelPageOfferPageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState('Modal Oferta');
  const [isUpdate, setIsUpdate] = useState(false);

  const history = useHistory();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(valiationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      slides: [
        {
          title: '',
          offer: '',
          web: '',
          mobile: '',
          imageTitle: '',
          subtitle: '',
          description: '',
          oldValue: 0,
          finalValue: '',
          textValue: '',
          buttonTitle: '',
          buttonLink: '',
          isTarget: false,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'slides' });
  const { id } = useParams<ParamasProps>();

  const { handleSetFinalValueAndButtonLink, options } =
    useRequestOfferProductSellGroup({ setValue });
  const {
    isLoadingCreateOfferFunnelPage,
    tryToRequestCreatingOfferFunnelPage,
  } = useTryToRequestCreatingOfferFunnelPage();
  const { handleUpdateOfferPage, isLoadingUpdateOffer } = useRequestOfferPage({
    isUpdate,
    setIsUpdate,
    setTitlePage,
    setValue,
  });

  const handleOffer = useCallback(
    async (e: Omit<OfferFormData, 'name'>) => {
      if (isUpdate) {
        return await handleUpdateOfferPage(e, titlePage);
      }
      await tryToRequestCreatingOfferFunnelPage({ name: titlePage, ...e });
    },
    [isUpdate, tryToRequestCreatingOfferFunnelPage, titlePage]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  return (
    <LayoutFunnelPage>
      <TitleFunnelPage titlePage={titlePage} setTitlePage={setTitlePage} />
      <Row>
        <Form
          layout='vertical'
          requiredMark='optional'
          style={{
            width: '100%',
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
          onFinish={handleSubmit(handleOffer)}
        >
          <Collapse defaultActiveKey={[fields[0].id]} expandIconPosition='end'>
            {fields.map((field, index) => (
              <Collapse.Panel
                header={`Slide ${index + 1}`}
                key={field.id}
                extra={
                  <Button
                    type='text'
                    style={{
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 1}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                }
              >
                <Row gutter={[24, 16]}>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].title`}
                      placeholder='Escreva o principal título da página.'
                      label='Título Principal'
                      defaultValue={field.title}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <SelectController
                      control={control}
                      name={`slides[${index}].offer`}
                      placeholder='Seleciona a oferta'
                      label='Oferta'
                      defaultValue={field.offer}
                      options={options}
                      onChange={value => {
                        handleSetFinalValueAndButtonLink(value, index);
                        setValue(`slides[${index}].offer` as any, value);
                      }}
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <UploadImage
                      tooltip={{
                        title: () => (
                          <Typography.Text>
                            <strong>Versão Web</strong> - 482 x 282 pixels (jpg,
                            jpeg, png)
                          </Typography.Text>
                        ),
                      }}
                      control={control}
                      name={`slides[${index}].web`}
                      label='Versão Web'
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <UploadImage
                      tooltip={{
                        title: () => (
                          <Typography.Text>
                            <strong>Versão App</strong> - 343 x 124 pixels (jpg,
                            jpeg, png)
                          </Typography.Text>
                        ),
                      }}
                      control={control}
                      name={`slides[${index}].mobile`}
                      label='Versão Mobile'
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].imageTitle`}
                      placeholder='Título do lado da imagem'
                      label='Título do lado da imagem'
                      defaultValue={field.imageTitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].subtitle`}
                      placeholder='Subtítulo'
                      label='Subtítulo'
                      defaultValue={field.subtitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={24}>
                    <TextEditController
                      control={control}
                      name={`slides[${index}].description`}
                      defaultValue={field.description}
                      placeholder='Apresente essa forma de contato.'
                      label='Descrição'
                      required
                    />
                  </Col>
                  <ContainerAddonAfter span={12}>
                    <InputNumberController
                      control={control}
                      name={`slides[${index}].oldValue`}
                      label='Valor antigo (De:)'
                      defaultValue={field.oldValue}
                      formatter={val =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(val))
                      }
                      parser={currencyParser}
                      placeholder='R$0.00'
                      required
                      style={{ width: '100%' }}
                    />
                  </ContainerAddonAfter>
                  <ContainerAddonAfter disabled span={12}>
                    <InputNumberController
                      control={control}
                      name={`slides[${index}].finalValue`}
                      label='Valor da oferta (Por:)'
                      defaultValue={field.finalValue}
                      formatter={val =>
                        new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(Number(val))
                      }
                      addonAfter={<Typography.Text>$</Typography.Text>}
                      disabled
                      parser={currencyParser}
                      placeholder='R$0.00'
                      required
                      style={{ width: '100%' }}
                    />
                  </ContainerAddonAfter>
                  <Col span={24}>
                    <Controller
                      control={control}
                      name={`slides[${index}].textValue`}
                      placeholder='Área de texto'
                      label='Texto em baixo do valor'
                      defaultValue={field.textValue}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <Col span={12}>
                    <Controller
                      control={control}
                      name={`slides[${index}].buttonTitle`}
                      placeholder='Título do botão'
                      label='Título do botão 1'
                      defaultValue={field.buttonTitle}
                      showCount={true}
                      maxLength={250}
                      required
                      autoComplete='off'
                    />
                  </Col>
                  <ContainerAddonAfter disabled span={12}>
                    <Controller
                      disabled
                      control={control}
                      name={`slides[${index}].buttonLink`}
                      defaultValue={field.buttonLink}
                      placeholder='Link'
                      autoComplete='off'
                      label='Link do botão'
                      showCount={true}
                      maxLength={250}
                      required
                      prefix={
                        <ThunderboltOutlined style={{ color: '#1668DC' }} />
                      }
                    />
                    <ContainerCheckbox>
                      <CheckboxController
                        control={control}
                        name={`slides[${index}].isTarget`}
                        defaultValue={field.isTarget}
                      >
                        Abrir em uma página externa
                      </CheckboxController>
                    </ContainerCheckbox>
                  </ContainerAddonAfter>
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Divider orientation='center' style={{ marginTop: '398px' }} />
          <Row justify='space-between'>
            <Col>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  append({
                    title: '',
                    offer: '',
                    web: '',
                    mobile: '',
                    imageTitle: '',
                    subtitle: '',
                    description: '',
                    oldValue: 0,
                    finalValue: '',
                    textValue: '',
                    buttonTitle: '',
                    buttonLink: '',
                    isTarget: false,
                  });
                }}
                disabled={fields.length >= 10}
              >
                Adicionar mais uma opção
              </Button>
            </Col>
            <Row gutter={[8, 0]}>
              <Col>
                <Button type='default' onClick={() => history.goBack()}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={
                    isUpdate
                      ? isLoadingUpdateOffer
                      : isLoadingCreateOfferFunnelPage
                  }
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </Row>
    </LayoutFunnelPage>
  );
};

const ContainerAddonAfter = styled(Col)<{ disabled?: boolean }>`
  .ant-input-group-addon {
    border: 1px solid #434343;
    border-left: 0px;
    ${theme =>
      theme.disabled &&
      css`
        background-color: rgba(255, 255, 255, 0.08);
      `}
  }
`;

const ContainerCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-control-input {
    min-height: 0;
  }
`;
