import { useCoupon } from '@hooks';
import { PageHeader } from '@organisms';
import React from 'react';

import { AddCouponModal } from './components/AddCouponModal/AddCouponModal.component';
import { FilterCoupons } from './Filter.coupons';
import { TableCoupon } from './Table.coupons';

export const CouponPage: React.FC = () => {
  const {
    couponList,
    isLoadingData,
    isSelectAddCouponModalOpen,
    handleChangePagination,
    handleFilterCoupon,
    handleOpenModalCoupon,
    handleCloseModalCoupon,
    handleOrderCoupon,
  } = useCoupon();

  return (
    <>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={'Gestão de Cupons'}
        subTitle={'Cadastrar, editar e configurar todos os cupons do checkout.'}
      />

      <TableCoupon
        onChangePagination={handleChangePagination}
        filterComponent={
          <FilterCoupons
            onAddCoupon={handleOpenModalCoupon}
            onFilterCoupon={handleFilterCoupon}
          />
        }
        data={couponList}
        onChangeOrder={handleOrderCoupon}
        isLoading={isLoadingData}
      />
      <AddCouponModal
        modalIsOpen={isSelectAddCouponModalOpen}
        onCloseModal={handleCloseModalCoupon}
      />
    </>
  );
};
