import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { FC } from 'react';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { customerAdyenReportApi } from 'src/features/clients/api/customerAdyenReportApi';
import {
  AdyenTransaction,
  IRecoveryEngineDto,
} from 'src/features/clients/types/clientTypes';

export type TableProps = {
  transactionsCancelation?: AdyenTransaction[];
};

export const CancelationTabsComponent: FC<TableProps> = ({
  transactionsCancelation,
}) => {
  const cancelAutoRescue = async (subscriptionId?: number) => {
    if (subscriptionId) {
      const response = await customerAdyenReportApi.adyenCancelAutoRescue(
        subscriptionId
      );
      if (response?.error) {
        OpenNotification(true, `${response.error}`);
        return;
      }
      OpenNotification(false, 'Auto rescue cancelado com sucesso!', '');
      return;
    }
  };

  const DropdownComponent = (item: AdyenTransaction) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                data-testid='cancel-auto-rescue'
                onClick={() => cancelAutoRescue(item.subscriptionId)}
                key='cancel-auto-rescue'
              >
                Cancelar Auto rescue
              </Menu.Item>
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  const columnCancelation = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
    },
    {
      title: 'Transação',
      dataIndex: 'subscriptionType',
    },
    {
      title: 'Status',
      dataIndex: 'transactionStatusId',
    },
    {
      title: 'Metodo de Pagamento',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      render: (fullPrice: number) => <>{convertToCurrency(fullPrice)}</>,
    },
    {
      title: 'ID da Assinatura',
      dataIndex: 'subscriptionId',
    },
    {
      title: 'Motor de Recuperação',
      dataIndex: 'recoveryEngine',
      render: (value: IRecoveryEngineDto) =>
        value?.status === 'running' ? 'Sim' : 'Não',
    },
    {
      title: 'Recuperar em',
      dataIndex: 'recoveryEngine',
      render: (value: IRecoveryEngineDto) =>
        value?.nextScheduledAttemptAt ? (
          <div>{dateFormatted(value.nextScheduledAttemptAt, true)}</div>
        ) : (
          ''
        ),
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (paymentDate: string) => (
        <div>{dateFormatted(paymentDate, true)}</div>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (item: AdyenTransaction) => DropdownComponent(item),
    },
  ];

  if (transactionsCancelation?.length === 0) return <></>;

  return (
    <>
      <Table
        scroll={{ x: 1450 }}
        style={{ width: '100vw' }}
        columns={columnCancelation}
        dataSource={transactionsCancelation}
      />
    </>
  );
};
