import {
  OfferFormData,
  OfferPayload,
  ResponseOfferProductSellGroup,
} from '../types/offerPageTypings';
import { shortenString } from './shortenString';
import { uploadS3 } from './uploadS3';

export const offerProductSellGroup = (
  response: ResponseOfferProductSellGroup
) => {
  const factory = response.offers.map(offer => ({
    id: offer.id,
    name: offer.name,
    fullPrice: offer.fullPrice,
    link: 'https://checkoutdev.queimadiaria.com/checkout/131',
    label: `${offer.id} | ${shortenString(
      offer.name
    )} | ${offer.fullPrice.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })}`,
  }));

  return factory;
};

export const offerPayloadFactory = async (payload: OfferFormData) => {
  const factory: OfferPayload = {
    cancellationFunnelId: Number(payload.cancellationFunnelId),
    pageType: 'offer',
    name: payload.name,
    content: {
      slides: await Promise.all(
        payload.slides.map(async slide => ({
          title: slide.title,
          offerId: slide.offer,
          imageWeb:
            typeof slide.web === 'string'
              ? slide.web
              : await uploadS3(slide.web),
          imageMobile:
            typeof slide.mobile === 'string'
              ? slide.mobile
              : await uploadS3(slide.mobile),
          imageTitle: slide.imageTitle,
          subTitle: slide.subtitle,
          description: slide.description,
          valueBefore: slide.oldValue,
          valueAfter: Number(slide.finalValue),
          textValue: slide.textValue,
          buttonName: slide.buttonTitle,
          buttonLink: slide.buttonLink,
          isTargetBlank: slide.isTarget,
        }))
      ),
    },
  };

  return factory;
};

export const offerResponseFactory = (response: OfferPayload) => {
  const factory: OfferFormData = {
    cancellationFunnelId: String(response.cancellationFunnelId),
    name: response.name,
    slides: response.content.slides.map(slide => ({
      isTarget: slide.isTargetBlank,
      buttonLink: slide.buttonLink,
      buttonTitle: slide.buttonName,
      textValue: slide.textValue,
      finalValue: String(slide.valueAfter),
      oldValue: slide.valueBefore,
      description: slide.description,
      subtitle: slide.subTitle,
      imageTitle: slide.imageTitle,
      web: slide.imageWeb,
      mobile: slide.imageMobile,
      offer: slide.offerId,
      title: slide.title,
    })),
  };

  return factory;
};
