import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { MediaFormData } from '../../types/mediaPageTypings';
import { useTryToRequestMediaPage } from './useTryToRequestMediaPage';
import { useTryToRequestMediaQuestionPage } from './useTryToRequestUpdateMediaPage';

interface UseRequestMediaPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    slides: {
      title: string;
      web: string;
      mobile: string;
      imageTitle: string;
      description: string;
      informativeNumeric: string;
      informativeText: string;
      buttonTitle: string;
      buttonLink: string;
      isTarget: boolean;
    }[];
  }>;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useRequestMediaPage({
  isUpdate,
  setIsUpdate,
  titlePage,
  setTitlePage,
  setValue,
}: UseRequestMediaPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestMediaPage } = useTryToRequestMediaPage();
  const { tryToRequestUpdateMediaPage, isLoadingMediaUpdate } =
    useTryToRequestMediaQuestionPage();

  const handleUpdateMediaPage = useCallback(
    async (e: Omit<MediaFormData, 'name'>) => {
      await tryToRequestUpdateMediaPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [tryToRequestUpdateMediaPage]
  );

  const handleRequestMediaPage = useCallback(async () => {
    await tryToRequestMediaPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      response.slides.forEach((slide: any, index) => {
        const slidePrefix = `slides[${index}]`;

        Object.keys(slide).forEach(key => {
          const field = `${slidePrefix}.${key}`;
          const value = slide[key];
          setValue(field as any, value);
        });
      });
      setTitlePage(response.name);
    });
  }, [setTitlePage, setValue]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestMediaPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { handleUpdateMediaPage, isLoadingMediaUpdate };
}
