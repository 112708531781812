import { PageHeader } from '@organisms';
import { Spin } from 'antd';

import { LayoutConfirmationFooter } from '../../molecules/LayoutConfirmationFooter/LayoutConfirmationFooter.molecule';
import { CouponForm } from './components/CouponForm/CouponForm.component';
import { useCoupon } from './hooks/useCoupon';

export function CreateCouponPage() {
  const {
    handleCancel,
    dispatchSubmit,
    onSaveCoupon,
    formRef,
    isLoading,
    setIsLoading,
  } = useCoupon();

  return (
    <Spin spinning={isLoading}>
      <LayoutConfirmationFooter
        onCancel={handleCancel}
        onSave={dispatchSubmit}
        cancelLabelButton='Descartar'
        ableToSave
      >
        <PageHeader
          backToPath={() => {
            window.history.back();
          }}
          title={'Formulário para a criação do Cupom'}
          subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
        />

        <CouponForm
          formRef={formRef}
          onFinish={onSaveCoupon}
          onLoading={setIsLoading}
        />
      </LayoutConfirmationFooter>
    </Spin>
  );
}
