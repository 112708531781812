import { CloseOutlined } from '@ant-design/icons';
import { FlameIcon } from '@assets/icons/flame';
import NewLoginImage from '@assets/images/novo-login.png';
import { EmptyButton } from '@atoms';
import { Modal } from '@models';
import { VideoPlayer } from '@organisms';

import { env } from 'src/app/env';

import * as S from './Highlite.style';

interface HighliteProps {
  onClose: () => void;
  modalInfo?: Modal.IModal;
}

const DEFAULT_TITLE = 'Novo Login';
const DEFAULT_DESCRIPTION =
  'Estamos lançando o novo Login, uma experiência de acesso totalmente aprimorada.';
const DEFAULT_BUTTON_LABEL = 'Treinar Agora';

export const Highlite = ({ onClose, modalInfo }: HighliteProps) => {
  const imageSrc = !!modalInfo?.advertisements[0].webImageFileId
    ? `${env.FILE_API}/files/files/view/${modalInfo?.advertisements[0].webImageFileId}`
    : NewLoginImage;

  return (
    <S.Content>
      <S.Header>
        <FlameIcon />
        <EmptyButton onClick={() => onClose()}>
          <CloseOutlined style={{ color: '#000', fontSize: '20px' }} />
        </EmptyButton>
      </S.Header>

      <S.Body>
        <S.BodyContent>
          {!modalInfo?.advertisements[0].videoLink && (
            <S.CustomImage preview={false} src={imageSrc} />
          )}

          {!!modalInfo?.advertisements[0].videoLink && (
            <VideoPlayer url={modalInfo?.advertisements[0].videoLink} />
          )}

          <S.Title>
            {modalInfo?.advertisements[0].title || DEFAULT_TITLE}
          </S.Title>

          <S.Description>
            {modalInfo?.advertisements[0].description || DEFAULT_DESCRIPTION}
          </S.Description>

          <S.BottomContainer>
            <S.CustomCheckbox onChange={e => () => null}>
              Não mostrar este conteúdo novamente.
            </S.CustomCheckbox>

            {!!modalInfo && !!modalInfo.advertisements[0].buttonLabel && (
              <S.CustomButton>
                {modalInfo?.advertisements[0].buttonLabel}
              </S.CustomButton>
            )}

            {!modalInfo && (
              <S.CustomButton>{DEFAULT_BUTTON_LABEL}</S.CustomButton>
            )}
          </S.BottomContainer>
        </S.BodyContent>
      </S.Body>
    </S.Content>
  );
};
