import {
  Controller,
  DatePickerController,
  SelectController,
  SwitchController,
} from '@organisms';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

import { SwitchGroupController } from 'src/app/framework/organisms/Controllers/SwitchGroupController.organism';

interface FormSensitiveDataProps {
  control: any;
  isEdit: boolean;
  isOfferStandard: boolean;
}

export const FormSensitiveData: FC<FormSensitiveDataProps> = ({
  control,
  isEdit,
  isOfferStandard,
}: FormSensitiveDataProps) => {
  function generateInstallments() {
    const objects = [];
    for (let i = 1; i <= 12; i++) {
      const object = {
        value: i.toString(),
        label: i.toString(),
      };
      objects.push(object);
    }
    return objects;
  }

  const { Text } = Typography;

  const switchOptions = [
    { value: 'individual', label: 'Individual' },
    { value: 'duo', label: 'Duo' },
    { value: 'family', label: 'Familia' },
  ];

  return (
    <Row>
      <Text
        style={{
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '28px',
          marginBlock: '40px 24px',
        }}
      >
        Dados Sensíveis
      </Text>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Col style={{ width: '177px' }}>
          <Controller
            disabled={isEdit && true}
            prefix='R$ '
            control={control}
            name={`fullPrice`}
            defaultValue={''}
            placeholder='0,00'
            autoComplete='off'
            label='Valor da Oferta'
            required
            type='number'
            tooltip={{
              title: () => (
                <Typography.Text>
                  O valor da oferta não pode ser alterado.
                </Typography.Text>
              ),
            }}
          />
        </Col>
        <Col style={{ marginInlineStart: '24px', width: '112px' }}>
          <SelectController
            label={'Parcelamento'}
            required
            options={generateInstallments()}
            defaultValue={generateInstallments()[11].value}
            control={control}
            name={'installments'}
            tooltip={{
              title: () => (
                <Typography.Text>
                  Banner Lateral - 225 x 754 pixels ( mp4, jpg, jpeg, png ou
                  gif)
                </Typography.Text>
              ),
            }}
          />
        </Col>
        <Col style={{ marginInlineStart: '24px', width: '420px' }}>
          <DatePickerController
            showTime={true}
            label='Selecione a data de ativação da oferta'
            control={control}
            name={'startDate'}
            format='DD/MM/YYYY HH:mm:ss'
          />
          <DatePickerController
            showTime={true}
            label='Selecione a data de expiração da oferta'
            control={control}
            name={'endDate'}
            format='DD/MM/YYYY HH:mm:ss'
            disabled={isEdit && isOfferStandard}
          />
        </Col>
        <Col style={{ marginInlineStart: '24px', minWidth: '420px' }}>
          <Controller
            control={control}
            showCount
            name={`purchaseBtnLabel`}
            placeholder='Compre Agora'
            autoComplete='off'
            label='Descritivo do botão'
            maxLength={54}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%', justifyContent: 'space-between' }}>
        <Col style={{ minWidth: '49%', marginInlineEnd: '12px' }}>
          <Controller
            control={control}
            name={`termsRef`}
            defaultValue={'https://www.queimadiaria.com/termos-de-uso/'}
            placeholder=''
            autoComplete='off'
            label='Link para termos de responsabilidade'
            required
          />
        </Col>
        <Col style={{ marginInlineStart: '12px', minWidth: '49%' }}>
          <Controller
            control={control}
            name={`privacyPolicyRef`}
            defaultValue={
              'https://www.queimadiaria.com/politica-de-privacidade/'
            }
            placeholder=''
            autoComplete='off'
            label='Link para politica de privacidade'
            required
          />
        </Col>
      </Row>
      <Row style={{ width: '100%', paddingBlockEnd: '30px' }}>
        <SwitchController
          control={control}
          defaultValue={false}
          name='offerStandart'
          sideLabel
          leftLabel='Esta oferta substitui a oferta padrão?'
          required
          tooltip={{
            title: () => (
              <Typography.Text>
                Somente um usuário master pode ativar essa opção.
              </Typography.Text>
            ),
          }}
        />
      </Row>
      <Row style={{ width: '100%' }}>
        <SwitchGroupController
          control={control}
          name='plan'
          label='Planos'
          options={switchOptions}
        />
      </Row>
    </Row>
  );
};
