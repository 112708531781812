import { DesktopOutlined, ShopOutlined } from '@ant-design/icons';
import { Modal, Row } from 'antd';

import { AddCouponCardComponent } from '../AddCouponCard/AddCouponCard.component';

interface AddCouponModalProps {
  modalIsOpen: boolean;
  onCloseModal: () => void;
}

const CardCouponOptions = [
  {
    title: 'Produto digital',
    route: '/teste/route',
    icon: <DesktopOutlined />,
    disabled: true,
  },
  {
    title: 'Produto físico',
    route: '/screens/coupons/create',
    icon: <ShopOutlined />,
  },
];

export const AddCouponModal = ({
  modalIsOpen,
  onCloseModal,
}: AddCouponModalProps) => {
  return (
    <Modal
      title='Escolha um tipo de cupom'
      open={modalIsOpen}
      footer={false}
      onCancel={onCloseModal}
    >
      <Row gutter={[32, 32]}>
        {CardCouponOptions.map((card, index) => (
          <AddCouponCardComponent
            key={index}
            icon={card.icon}
            route={card.route}
            title={card.title}
            disabled={card.disabled}
          />
        ))}
      </Row>
    </Modal>
  );
};
