import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SwitchController, TextAreaController } from '@organisms';
import { Button, Col, Divider, Form, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';

import { BackButton } from 'src/app/components/BackButton';
import { useQueryParams } from 'src/app/hooks/useQueryParams';
import { useTryToRequestCreatingSpecialistFunnelPage } from 'src/features/Funnel/hooks/specialist/useTryToRequestCreatingSpecialistFunnelPage';
import { useUpdateScepialistFunnelPage } from 'src/features/Funnel/hooks/specialist/useUpdateSpecialistFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { SpecialistFormData } from 'src/features/Funnel/types/specialistPageTypings';

import { TitleFunnelPage } from '../../../Components/TitleFunnelPage';

const validationSchema = yup.object({
  id: yup.string().nullable(),
  title: yup.string().required('O Título é obrigatório'),
  subtitle: yup.string().nullable(),
  buttonTitle: yup.string().required('O Título do Botão é obrigatório'),
  buttonTitleWhatsApp: yup
    .string()
    .required('O Título do Botão WhatsApp é obrigatório'),
  email: yup.string().required('O E-mail é obrigatório'),
  whatsApp: yup.string().required('o WhatsApp é obrigatório'),
  interaction: yup.boolean().required('o Interação com analista é obrigatório'),
});

export const FunnelSpecialistCarePageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState<string>('Modal de Suporte');
  const [isEditPage, setIsEditPage] = useState(false);
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      title: '',
      subtitle: '',
      buttonTitle: '',
      buttonTitleWhatsApp: '',
      email: '',
      whatsApp: '',
      interaction: false,
    },
  });
  const history = useHistory();
  const { id } = useParams<ParamasProps>();
  const params = useQueryParams();
  const pageId = params.get('pageId');
  const [isUpdate, setIsUpdate] = useState(false);

  const { tryToRequestCreatingSpecialistFunnelPage, isLoading } =
    useTryToRequestCreatingSpecialistFunnelPage();

  const {
    handleGetOneSpecialistFunnelPage,
    handleUpdateSpecialistFunnelPage,
    isLoadingSpecialistPageFunnelUpdate,
  } = useUpdateScepialistFunnelPage(setValue);

  const handleSpecialistCarePagePage = useCallback(
    async (e: Omit<SpecialistFormData, 'titlePage'>) => {
      if (isUpdate && pageId) {
        return handleUpdateSpecialistFunnelPage({ titlePage, ...e }, pageId);
      }
      return await tryToRequestCreatingSpecialistFunnelPage({
        titlePage: titlePage,
        ...e,
      });
    },
    [titlePage, isUpdate, pageId]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  useEffect(() => {
    if (pageId) {
      handleGetOneSpecialistFunnelPage(pageId, setTitlePage);
      setIsUpdate(true);
    }
  }, [pageId]);

  return (
    <Col
      span={24}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '22px',
      }}
    >
      <BackButton />
      <Row style={{ justifyContent: 'center' }}>
        <Col
          span={12}
          style={{
            minWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <TitleFunnelPage
            titlePage={titlePage}
            setTitlePage={setTitlePage}
            isEditTitle={isEditPage}
            setIsEditTitle={setIsEditPage}
          />
          <Row>
            <Form
              layout='vertical'
              requiredMark={'optional'}
              style={{
                width: '100%',
                gap: '24px',
                display: 'flex',
                flexDirection: 'column',
              }}
              onFinish={handleSubmit(handleSpecialistCarePagePage)}
            >
              <Controller
                control={control}
                name='title'
                placeholder='Escreva o principal título da página.'
                defaultValue={control._defaultValues.title}
                autoComplete='off'
                label='Título Principal'
                showCount={true}
                maxLength={250}
                required
              />
              <TextAreaController
                control={control}
                name='subtitle'
                placeholder='Área de texto'
                defaultValue={control._defaultValues.subtitle}
                autoComplete='off'
                label='Subtítulo'
                isMaxLength={true}
                maxLength={250}
                row={2}
                required={false}
              />
              <Row style={{ flexDirection: 'row', gap: '24px', width: '100%' }}>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='buttonTitle'
                    placeholder='Título do botão'
                    defaultValue={control._defaultValues.buttonTitle}
                    autoComplete='off'
                    label='Título do botão e-mail'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                </Col>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='email'
                    placeholder='Digite o e-mail'
                    defaultValue={control._defaultValues.email}
                    autoComplete='off'
                    label='E-mail'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                </Col>
              </Row>
              <Row style={{ flexDirection: 'row', gap: '24px', width: '100%' }}>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='buttonTitleWhatsApp'
                    label='Título do botão WhatsApp'
                    placeholder='Título do botão'
                    defaultValue={control._defaultValues.buttonTitleWhatsApp}
                    autoComplete='off'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                </Col>
                <Col flex={1}>
                  <Controller
                    control={control}
                    name='whatsApp'
                    label='WhatsApp'
                    placeholder='https://wa.me/55DDNumero'
                    defaultValue={control._defaultValues.whatsApp}
                    autoComplete='off'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                </Col>
              </Row>
              <SwitchController
                control={control}
                name='interaction'
                label='Interação com analista'
                defaultValue={control._defaultValues.interaction}
                required
                description='Ativar obrigatoriedade de conversa com analista'
              />
              <Divider orientation='center' />
              <Row style={{ justifyContent: 'end' }}>
                <Row style={{ gap: '8px' }}>
                  <Button type='default' onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={
                      isUpdate ? isLoadingSpecialistPageFunnelUpdate : isLoading
                    }
                  >
                    Salvar
                  </Button>
                </Row>
              </Row>
            </Form>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
