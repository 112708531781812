import {
  OrderedListOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useSegments } from '@hooks';
import { Assets } from '@models';
import { FilterHeader } from '@organisms';
import { Button, DatePicker, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ASSETS_TYPE_LIST } from 'src/app/constants/assets';

interface FilterAssetsProps {
  onAddAsset: () => void;
  onSortAsset: () => void;
  onFilterAssets: (handle: { field: string; value?: string }[]) => void;
}

export const FilterAssets: React.FC<FilterAssetsProps> = ({
  onAddAsset,
  onSortAsset,
  onFilterAssets,
}) => {
  const { segmentsList, getSegmentsList } = useSegments();
  const [typeFilter, setTypeFilter] = useState<Assets.AssetContext>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [dateFilter, setDateFilter] =
    useState<[moment.Moment | null, moment.Moment | null]>();
  const [filterBysegment, setFilterBysegment] = useState([]);

  useEffect(() => {
    getSegmentsList({ take: 99999 });
  }, []);

  const handleTypeFilter = (type: string) => {
    onFilterAssets([{ field: 'context', value: type }]);
  };

  return (
    <FilterHeader
      buttonList={[
        <Select
          placeholder={'Tipo'}
          data-testid={'asset-type-filter-select'}
          onChange={value => {
            setTypeFilter(value);
            handleTypeFilter(value);
          }}
          options={ASSETS_TYPE_LIST.map(assetType => ({
            label: assetType.title,
            value: assetType.value,
          }))}
          style={{ width: '180px' }}
          allowClear
          value={typeFilter}
        />,

        <DatePicker.RangePicker
          style={{ width: '218px' }}
          onChange={moment => {
            setDateFilter(moment || undefined);

            if (!!moment?.[0] && !!moment[1]) {
              onFilterAssets([
                {
                  field: 'startDate',
                  value: moment[0].format('yyyy-MM-DD'),
                },
                {
                  field: 'endDate',
                  value: moment[1].format('yyyy-MM-DD'),
                },
              ]);
            } else {
              onFilterAssets([
                {
                  field: 'startDate',
                  value: undefined,
                },
                {
                  field: 'endDate',
                  value: undefined,
                },
              ]);
            }
          }}
          data-testid={'asset-date-filter-select'}
          format={'DD/MM/YYYY'}
          defaultValue={dateFilter}
        />,

        <Select
          placeholder={'Status'}
          data-testid={'asset-data-filter'}
          onChange={value => {
            setStatusFilter(value);

            if (value === undefined) {
              onFilterAssets([{ field: 'activated', value: undefined }]);
              return;
            }

            onFilterAssets([
              { field: 'activated', value: String(value === 'ACTIVE') },
            ]);
          }}
          style={{ width: '114px' }}
          value={statusFilter}
          allowClear
        >
          <Select.Option value='ACTIVE'>Ativo</Select.Option>
          <Select.Option value='INACTIVE'>Inativo</Select.Option>
        </Select>,

        <Select
          placeholder={'Segmento'}
          data-testid={'asset-segment-filter-select'}
          onChange={value => {
            setFilterBysegment(value);

            if (!value) {
              onFilterAssets([{ field: 'segmentIds', value: undefined }]);
              return;
            }
            onFilterAssets([{ field: 'segmentIds', value: value.toString() }]);
          }}
          options={segmentsList?.rows?.map(segment => ({
            label: segment.name,
            value: segment.id,
          }))}
          optionFilterProp='label'
          style={{ width: '216px' }}
          allowClear
          value={filterBysegment}
          showSearch
          suffixIcon={<SearchOutlined />}
        />,
      ]}
      customFilter={[
        <Button
          key={'filter-header-button-add-assets'}
          icon={<PlusCircleOutlined />}
          type='primary'
          onClick={onAddAsset}
        >
          Adicionar assets
        </Button>,
        <Button
          key={'filter-header-button-sort-assets'}
          icon={<OrderedListOutlined />}
          type='primary'
          onClick={onSortAsset}
        >
          Ordenação
        </Button>,
      ]}
    />
  );
};
