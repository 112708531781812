import { RcFile } from 'antd/lib/upload';

import { guestApi } from 'src/app/guestApi';

import { FunctionalityPayload } from '../types/functionalityTypings';
import { FunnelPublishingPayload } from '../types/FunnelEditTypings';
import { MediaPayload } from '../types/mediaPageTypings';
import { OfferPayload } from '../types/offerPageTypings';
import { QuestionPayload } from '../types/questionPageTypings';
import { ReleasePayload } from '../types/releasePageTypings';
import { SpecialistPayload } from '../types/specialistPageTypings';

async function tryToRequestAllCancellationFunnels() {
  const endpoint = 'cancellation-funnel';
  const response = await guestApi.get(endpoint);

  return response;
}

async function tryToRqeuestCancellationFunnelsPublishing(
  payload: FunnelPublishingPayload[]
) {
  const endpoint = `cancellation-funnel-page`;
  const response = await guestApi.patch(endpoint, payload);

  return response;
}

async function tryToRequestFunnelsPages(id: string) {
  const endpoint = `cancellation-funnel-page/funnel/${id}`;

  const response = await guestApi.get(endpoint);

  return response;
}

async function tryToRequestGetOneCancellationFunnelPage(id: string) {
  const endpoint = `cancellation-funnel-page/${id}`;

  const response = await guestApi.get(endpoint);

  return response;
}

async function tryToRequestUpdateCancellationFunnelPage(
  id: string,
  payload: any
) {
  const endpoint = `cancellation-funnel-page/${id}`;

  const response = await guestApi.patch(endpoint, payload);

  return response;
}

async function tryToRequestRemoveCancellationFunnelPage(id: string) {
  const endpoint = `cancellation-funnel-page/${id}`;

  const response = await guestApi.delete(endpoint);

  return response;
}

async function tryToRequestUpload(file: RcFile) {
  const endpoint = 'cancellation-funnel-page/upload';
  const formData = new FormData();
  formData.append('file', file);

  const response = await guestApi.post(endpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
}

async function tryToRequestCreatingSpecialistFunnelPage(
  payload: SpecialistPayload
) {
  const endpoint = 'cancellation-funnel-page/specialist';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

async function tryToRequestCreatingQuestionFunnelPage(
  payload: QuestionPayload
) {
  const endpoint = 'cancellation-funnel-page/question';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

async function tryToRequestCreatingMediaFunnelPage(payload: MediaPayload) {
  const endpoint = 'cancellation-funnel-page/media';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

async function tryToRequestCreatingFunctionality(
  payload: FunctionalityPayload
) {
  const endpoint = 'cancellation-funnel-page/functionality';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

async function tryToRequestCreatingRelease(payload: ReleasePayload) {
  const endpoint = 'cancellation-funnel-page/release';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

async function tryToRequestProductSellGroup(id: number) {
  const endpoint = `products/product-sell-group/${id}`;

  const response = await guestApi.get(endpoint);

  return response;
}

async function tryToRequestCreatingOffer(payload: OfferPayload) {
  const endpoint = 'cancellation-funnel-page/offer';
  const response = await guestApi.post(endpoint, payload);

  return response;
}

export const apiCancellationFunnels = {
  tryToRequestAllCancellationFunnels,
  tryToRqeuestCancellationFunnelsPublishing,
  tryToRequestFunnelsPages,
  tryToRequestGetOneCancellationFunnelPage,
  tryToRequestUpdateCancellationFunnelPage,
  tryToRequestRemoveCancellationFunnelPage,
  tryToRequestUpload,
  tryToRequestCreatingSpecialistFunnelPage,
  tryToRequestCreatingQuestionFunnelPage,
  tryToRequestCreatingMediaFunnelPage,
  tryToRequestCreatingFunctionality,
  tryToRequestCreatingRelease,
  tryToRequestProductSellGroup,
  tryToRequestCreatingOffer,
};
