import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';

interface DatePickerControllerProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  defaultValue?: string | Date;
  autoComplete?: string;
  onChange?: () => void;
  prefix?: React.ReactNode;
  type?: string;
  label?: string;
  format?: string;
  showTime?: boolean;
  disabled?: boolean;
  disableDate?: (currentDate: moment.Moment) => boolean;
}

export const DatePickerController: React.FC<DatePickerControllerProps> = ({
  control,
  defaultValue,
  name,
  label,
  placeholder,
  format,
  showTime,
  disabled,
  disableDate,
}) => {
  return (
    <ControllerHookForm
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={props => {
        const { status, help } = toFieldStatus(props.fieldState.error);

        return (
          <Form.Item label={label} validateStatus={status} help={help}>
            <DatePicker
              placeholder={placeholder}
              style={{ width: '100%' }}
              onChange={props.field.onChange}
              data-testid={name}
              showTime={showTime}
              format={format ?? 'DD/MM/YYYY'}
              disabledDate={disableDate}
              value={props.field.value ? moment(props.field.value) : undefined}
              disabled={disabled}
            />
          </Form.Item>
        );
      }}
    />
  );
};
