import { QuestionPayload } from '../types/questionPageTypings';

export const questionFunnelsPagesResponseFactory = (
  response: QuestionPayload
) => {
  const body = {
    cancellationFunnelId: response.cancellationFunnelId,
    subtitle: response.content.subtitle,
    title: response.content.title,
    name: response.name,
    answers: response.content.answers,
  };

  return body;
};
