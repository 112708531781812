import { slices } from '@core/redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@models';
import { modals } from '@service/api';
import moment from 'moment';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import OpenNotification from '../components/antd/OpenNotification';
import {
  ModalModelProperties,
  ModalScheduleProperties,
} from '../framework/pages/ModalsDetail/ModalDetail';
import { defaultContentSchema } from '../framework/pages/ModalsDetail/schema/defaultContentSchema';
import { modalModelSchema } from '../framework/pages/ModalsDetail/schema/modalModelSchema';
import { paginationContentSchema } from '../framework/pages/ModalsDetail/schema/paginationContentSchema';
import { scheduleValidationSchema } from '../framework/pages/ModalsDetail/schema/scheduleSchema';

export const useModalDetail = () => {
  const dispatch = useDispatch();

  const modelForm = useForm<ModalModelProperties>({
    mode: 'all',
    resolver: yupResolver(modalModelSchema),
  });

  const defaultForm = useForm<Modal.IDefaultContent>({
    mode: 'all',
    resolver: yupResolver(defaultContentSchema),
  });

  const highlightForm = useForm<Modal.IDefaultContent>({
    mode: 'all',
    resolver: yupResolver(defaultContentSchema),
  });

  const paginationForm = useForm<Modal.IPaginationContent>({
    mode: 'onSubmit',
    resolver: yupResolver(paginationContentSchema),
  });

  const schedulingForm = useForm<ModalScheduleProperties>({
    mode: 'all',
    resolver: yupResolver(scheduleValidationSchema),
  });

  const modalFormObject: { [key in Modal.TypeEnum]: any } = {
    [Modal.TypeEnum.DEFAULT]: defaultForm,
    [Modal.TypeEnum.HIGHLIGHT]: highlightForm,
    [Modal.TypeEnum.PAGINATION]: paginationForm,
    [Modal.TypeEnum.LAUNCH]: paginationForm,
  };
  const getCurrentContentForm = (): UseFormReturn<
    Modal.IDefaultContent | Modal.IPaginationContent
  > => {
    return modalFormObject[modelForm.getValues('modalType')];
  };

  const verifyHasImageToSaveInDefaultInfo = async (
    modalId: string,
    advertisementList: Modal.IModal['advertisements']
  ) => {
    const promises = [];
    const currentContent = getCurrentContentForm();
    if (currentContent.getValues('mediaType') !== 'IMAGE') return;

    const imageWeb = currentContent.getValues('imageWeb') as any;
    const imageMobile = currentContent.getValues('imageMobile') as any;

    if (typeof imageWeb !== 'string') {
      const webFormData = new FormData();
      webFormData.append(
        'file',
        imageWeb.file.originFileObj,
        imageWeb.file.name
      );
      webFormData.append('device', 'web');

      promises.push(
        await modals.createModalImageReference(
          { modalId, advertisementId: advertisementList[0].id || '' },
          webFormData
        )
      );
    }

    if (typeof imageMobile !== 'string') {
      const mobileFormData = new FormData();
      mobileFormData.append(
        'file',
        imageMobile.file.originFileObj,
        imageMobile.file.name
      );
      mobileFormData.append('device', 'mobile');

      promises.push(
        await modals.createModalImageReference(
          { modalId, advertisementId: advertisementList[0].id || '' },
          mobileFormData
        )
      );
    }
  };

  const verifyHasImageToSaveInPaginationInfo = async (
    modalId: string,
    advertisementList: Modal.IModal['advertisements']
  ) => {
    const modalContent = getCurrentContentForm().getValues('modalContent');
    const promises = [];

    modalContent.forEach((content, index) => {
      if (content.mediaType !== 'IMAGE') return null;
      const webFormData = new FormData();
      const imageWeb: any = content.imageWeb as any;
      const imageMobile: any = content.imageMobile as any;
      webFormData.append(
        'file',
        imageWeb.file.originFileObj,
        imageWeb.file.name
      );
      webFormData.append('device', 'web');

      const mobileFormData = new FormData();
      mobileFormData.append(
        'file',
        imageMobile.file.originFileObj,
        imageMobile.file.name
      );
      mobileFormData.append('device', 'mobile');

      promises.push(
        modals.createModalImageReference(
          { modalId, advertisementId: advertisementList[index].id || '' },
          webFormData
        )
      );

      promises.push(
        modals.createModalImageReference(
          { modalId, advertisementId: advertisementList[index].id || '' },
          mobileFormData
        )
      );
    });
  };

  const buildDefaultSaveInfo = () => {
    const currentContent = getCurrentContentForm();
    const saveInfo: Omit<Modal.IModal, 'id'> = {
      type: modelForm.getValues('modalType'),
      activated: schedulingForm.getValues('activated'),
      effectiveDateTime: moment(
        schedulingForm.getValues('effectiveDateTime')
      ).toISOString(),
      expirationDateTime: moment(
        schedulingForm.getValues('expirationDateTime')
      ).toISOString(),
      displayScreenLink: currentContent.getValues('urlLocalShow'),
      advertisements: [
        {
          title: currentContent.getValues('title'),
          description: currentContent.getValues('description'),
          buttonActionLink: currentContent.getValues('redirectButtonUrl'),
          buttonLabel: currentContent.getValues('textButton'),
          openExternalWindow: currentContent.getValues('openExternalWindow'),
        },
      ],
      modalSegments: [
        {
          segmentId: modelForm.getValues('segment'),
        },
      ],
    };

    if (currentContent.getValues('mediaType') === 'VIDEO') {
      saveInfo.advertisements[0] = {
        ...saveInfo.advertisements[0],
        videoLink: currentContent.getValues('urlVideoModal'),
      };
    }

    return saveInfo;
  };

  const buildPaginationSaveInfo = () => {
    const currentContent = getCurrentContentForm();

    return {
      type: modelForm.getValues('modalType'),
      activated: schedulingForm.getValues('activated'),
      effectiveDateTime: schedulingForm.getValues('effectiveDateTime'),
      expirationDateTime: schedulingForm.getValues('expirationDateTime'),
      displayScreenLink: currentContent.getValues('urlLocalShow'),
      advertisements: currentContent
        .getValues('modalContent')
        .map(paginationItem => {
          let item: Omit<Modal.IModal, 'id'>['advertisements'][0] = {
            title: paginationItem.title,
            description: paginationItem.description,
            buttonActionLink: paginationItem.redirectButtonUrl,
            buttonLabel: paginationItem.textButton,
            openExternalWindow: paginationItem.openExternalWindow,
          };

          if (paginationItem.mediaType === 'VIDEO') {
            item = {
              ...item,
              videoLink: paginationItem.urlVideoModal,
            };
          }

          return item;
        }),
      modalSegments: [
        {
          segmentId: modelForm.getValues('segment'),
        },
      ],
    };
  };

  const saveModal = async () => {
    dispatch(slices.layout.increaseLoading());
    try {
      const saveInfo: Omit<Modal.IModal, 'id'> =
        modelForm.getValues('modalType') === Modal.TypeEnum.PAGINATION
          ? buildPaginationSaveInfo()
          : buildDefaultSaveInfo();

      const savedModalProps = await modals.saveModal(saveInfo);

      modelForm.getValues('modalType') !== Modal.TypeEnum.PAGINATION
        ? verifyHasImageToSaveInDefaultInfo(
            savedModalProps.id,
            savedModalProps.advertisements
          )
        : verifyHasImageToSaveInPaginationInfo(
            savedModalProps.id,
            savedModalProps.advertisements
          );

      OpenNotification(false, 'Modal salva com sucesso!');
    } catch (error) {
      OpenNotification(true, 'Erro ao salvar o modal.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  const updateModal = async (modalId: string) => {
    dispatch(slices.layout.increaseLoading());
    try {
      const saveInfo: Omit<Modal.IModal, 'id'> =
        modelForm.getValues('modalType') === Modal.TypeEnum.PAGINATION
          ? buildPaginationSaveInfo()
          : buildDefaultSaveInfo();

      await modals.putModal(modalId, saveInfo);
      OpenNotification(false, 'Modal editada com sucesso!');
    } catch (error) {
      OpenNotification(true, 'Erro ao editar o modal.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
    }
  };

  return {
    getCurrentContentForm,
    saveModal,
    updateModal,
    schedulingForm,
    modelForm,
    highlightForm,
    defaultForm,
    paginationForm,
  };
};
