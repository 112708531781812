import { CloseOutlined } from '@ant-design/icons';
import { FlameIcon } from '@assets/icons/flame';
import NewLoginImage from '@assets/images/gerenciamento-contas-mobile.png';
import { EmptyButton } from '@atoms';
import { Modal } from '@models';
import { VideoPlayer } from '@organisms';
import { Carousel } from 'antd';
import { useState } from 'react';

import { env } from 'src/app/env';

import * as S from './PaginationMobile.style';

interface PaginationMobileProps {
  onClose: () => void;
  modalInfo?: Modal.IModal;
}

const DEFAULT_TITLE = 'Gerenciamento de Conta';
const DEFAULT_DESCRIPTION =
  'Estamos lançando o novo Gerenciamento de Conta, simplificando a administração dos seus dados e com controle dos seus dados.';
const DEFAULT_BUTTON_LABEL = 'Treinar Agora';

export const PaginationMobile = ({
  onClose,
  modalInfo,
}: PaginationMobileProps) => {
  const [carouselState, setCarouselState] = useState(0);

  return (
    <S.Content>
      <S.Header>
        <FlameIcon />
        <EmptyButton onClick={() => onClose()}>
          <CloseOutlined style={{ color: '#000', fontSize: '20px' }} />
        </EmptyButton>
      </S.Header>

      <S.Body>
        <S.BodyContent>
          <Carousel
            infinite={false}
            beforeChange={(_, nextPage) => setCarouselState(nextPage)}
            touchMove
          >
            {!!modalInfo &&
              modalInfo?.advertisements.map(adversement => {
                if (adversement.videoLink) {
                  return (
                    <VideoPlayer url={adversement.videoLink} height='320' />
                  );
                }

                return (
                  <div>
                    <S.CustomImage
                      preview={false}
                      src={`${env.FILE_API}/files/files/view/${adversement.webImageFileId}`}
                    />
                  </div>
                );
              })}

            {!modalInfo &&
              Array(3)
                .fill(null)
                .map((_, index) => (
                  <div key={index}>
                    <S.CustomImage preview={false} src={NewLoginImage} />
                  </div>
                ))}
          </Carousel>

          <S.Title>
            {modalInfo?.advertisements[carouselState].title || DEFAULT_TITLE}
          </S.Title>

          <S.Description>
            {modalInfo?.advertisements[carouselState].description ||
              DEFAULT_DESCRIPTION}
          </S.Description>

          <S.BottomContainer>
            <S.CustomCheckbox onChange={e => () => null}>
              Não mostrar este conteúdo novamente.
            </S.CustomCheckbox>

            <S.CustomButton>
              {modalInfo?.advertisements[carouselState].buttonLabel ||
                DEFAULT_BUTTON_LABEL}
            </S.CustomButton>
          </S.BottomContainer>
        </S.BodyContent>
      </S.Body>
    </S.Content>
  );
};
