// import { enviroments } from '@infrastructure/enviroments';
import { enviroments } from '@infrastructure/enviroments';
import { base } from '@service/base';
import { PageLimitFilter } from '@typings';

import { TCouponResponse } from 'src/models/Coupon.model';

const BASE_URL = enviroments.BASE_URL;

export async function getAllCoupon({
  page,
  limit,
  filter,
  order: ordered,
}: PageLimitFilter): Promise<TCouponResponse> {
  let where = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        where = {
          ...where,
          [key]: filter[key],
        };
      }
    });
  }

  const order = !ordered || ordered === '' ? '[id,ASC]' : ordered;

  const response = await base({
    baseURL: BASE_URL,
    url: `/sales/coupon`,
    method: 'GET',
    params: {
      ...where,
      limit,
      page,
      order,
    },
  });

  return {
    count: response.data.total,
    rows: response.data.data,
  };
}
