import { useCallback, useMemo } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { OffersProps } from '../../types/offerPageTypings';
import { useTryToRequestOfferProduct } from './useTryToRequestOfferProduct';

interface OptionsProps {
  value: string;
  label: string;
}

interface UseRequestOfferProductSellGroupProps {
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    slides: {
      title: string;
      offer: string;
      web: string;
      mobile: string;
      imageTitle: string;
      subtitle: string;
      description: string;
      oldValue: number;
      finalValue: string;
      textValue: string;
      buttonTitle: string;
      buttonLink: string;
      isTarget: boolean;
    }[];
  }>;
}

export function useRequestOfferProductSellGroup({
  setValue,
}: UseRequestOfferProductSellGroupProps) {
  const { offerProduct } = useTryToRequestOfferProduct(131);
  const options: OptionsProps[] = useMemo(
    () =>
      offerProduct
        ? offerProduct.map(offer => ({
            value: offer.id,
            label: offer.label,
          }))
        : [],
    [offerProduct]
  );

  const handleSetFinalValueAndButtonLink = useCallback(
    (value: string, index: number) => {
      const offer = (offerProduct as OffersProps[]).find(
        option => option.id === value
      );

      if (offer) {
        const { fullPrice, link } = offer;
        setValue(`slides[${index}].finalValue` as any, fullPrice);
        setValue(`slides[${index}].buttonLink` as any, link);
      }
    },
    [offerProduct]
  );

  return { options, handleSetFinalValueAndButtonLink };
}
