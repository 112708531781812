import { Affix, Form, Input, Layout, Radio } from 'antd';
import styled from 'styled-components';

export const Container = styled(Layout)`
  background-color: transparent;
`;

export const FormCoupon = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ErrorContent = styled.div`
  gap: 0px;
  border-radius: 8px;
  background: rgba(42, 18, 21, 1);
  border: 1px solid rgba(88, 24, 28, 1);
  padding-bottom: 16px;
  display: flex;
  margin-bottom: 32px;
  padding: 16px 24px;

  > div {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
  }

  > div > p {
    margin: 0 0 12px;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
  }

  > span svg {
    color: rgba(232, 71, 73, 1);
    width: 24px;
    height: 24px;
  }
`;

export const InputAreaContent = styled.div`
  gap: 0px;
  border-radius: 8px;
  background: rgba(31, 31, 31, 1);
  border: 1px solid rgba(66, 66, 66, 1);
  padding-bottom: 16px;

  > div {
    padding: 0 24px;
  }

  > header {
    padding: 12px 24px;
    border-bottom: 1px solid rgba(66, 66, 66, 1);
    margin-bottom: 16px;
  }
`;

export const InputFormCoupon = styled(Input)`
  background: rgba(20, 20, 20, 1);
  border: 1px solid rgba(66, 66, 66, 1);
  border-radius: 6px;
`;

export const TextAreaFormCoupon = styled(Input.TextArea)`
  background: rgba(20, 20, 20, 1);
  border: 1px solid rgba(66, 66, 66, 1);
  border-radius: 6px;
`;

export const RadioInput = styled(Radio)`
  margin-bottom: 8px;
`;

export const RadioInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    max-width: 280px;
  }
`;

export const SwitchInputContainer = styled.div`
  display: flex;
  gap: 10px;
  color: rgba(255, 255, 255, 0.45);
`;

export const SidePanelContainer = styled(Affix)`
  pointer-events: none;
`;
