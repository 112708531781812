import { base } from '@service/base';

import {
  PurchaseTypeAllowedEnum,
  TypeEnum,
} from '../../../models/Coupon.model';

export type TEditCouponProps = {
  id: number;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  discount: {
    type: TypeEnum;
    value: number;
  };
  maxUse: number;
  maxUsePerCustomer: number;
  active: boolean;
};

export async function editCoupon(data: TEditCouponProps) {
  const response = await base.put(`/sales/coupon`, data);

  return response.data;
}
