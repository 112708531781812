import { Button, Checkbox, Image } from 'antd';
import styled from 'styled-components';

export const BodyContent = styled.div``;
export const Title = styled.div`
  color: #525252;
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.div`
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  padding-top: 4px;
`;

export const CustomImage = styled(Image)`
  border-radius: 8px;
  margin-top: 24px;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #525252;
`;

export const CustomButton = styled(Button)`
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  background-color: #ff4f00;
  border: none;
  color: #fff;

  &:focus,
  :active {
    color: #fff;
    text-decoration: none;
    background-color: #ff4f00;
  }

  &:hover {
    color: #fff;
    background-color: #fc6824;
  }
`;

export const Content = styled.div`
  width: 688px;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 21px;
  padding: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Body = styled.div``;
export const CarouselContainer = styled.div`
  .slick-prev,
  .slick-next {
    font-size: 20px; /* ou o tamanho que desejar */
    color: #ccc; /* ou a cor que desejar */
    z-index: 1;
  }

  .slick-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .slick-next {
    position: absolute;
    right: 20px; /* ajuste conforme necessário */
    top: 50%;
    transform: translate(0, -50%);

    &:hover {
      color: initial;
      background-color: initial;
    }
  }
`;
