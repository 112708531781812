import { Modal } from '@models';
import {
  CheckboxController,
  Controller as OrganismController,
  RadioController,
  TextAreaController,
  UploadBiggerController,
} from '@organisms';
import { Col, Form, Row } from 'antd';
import { useEffect } from 'react';

import type { ModelStepBaseComponentProp } from '../ModalDetail';
const BASE_PATH =
  (process.env.REACT_APP_BASE_URL || '').indexOf('apidev') === -1
    ? 'https://play.queimadiaria.com'
    : 'https://dev.queimadiaria.com';

export const DefaultContent = ({
  form,
}: ModelStepBaseComponentProp<Modal.IDefaultContent>) => {
  useEffect(() => {
    form.setValue('urlLocalShow', `${BASE_PATH}/home`, {
      shouldValidate: true,
    });
  }, []);
  return (
    <Form layout='vertical'>
      <Row>
        <Col span={24}>
          <OrganismController
            {...form.register('urlLocalShow')}
            control={form.control}
            name='urlLocalShow'
            label='Local para exibição do modal'
            placeholder='Insira a URL onde o modal será exibido'
            autoComplete='off'
            showCount
            value={`${BASE_PATH}/home`}
            disabled
            maxLength={110}
            prefix='https://'
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: '24px' }}>
        <Col span={24}>
          <OrganismController
            {...form.register('title')}
            name='title'
            control={form.control}
            label='Titulo'
            placeholder='Titulo do modal'
            autoComplete='off'
            showCount
            maxLength={110}
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: '24px' }}>
        <Col span={24}>
          <TextAreaController
            {...form.register('description')}
            name='description'
            control={form.control}
            label='Descritivo'
            placeholder='Descrição do Modal'
            autoComplete='off'
            row={3}
            showCount
            maxLength={110}
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: '24px' }}>
        <Col span={24}>Tipo de mídia</Col>
      </Row>

      <Row style={{ paddingTop: '8px' }}>
        <Col span={24}>
          <RadioController
            {...form.register('mediaType')}
            control={form.control}
            name='mediaType'
            options={[
              {
                label: 'Vídeo (.mp4)',
                value: 'VIDEO',
              },
              {
                label: 'Imagem (.jpg, .png ou .gif)',
                value: 'IMAGE',
              },
            ]}
          />
        </Col>
      </Row>

      {form.watch('mediaType') === 'VIDEO' && (
        <Row style={{ paddingTop: '24px' }}>
          <Col span={24}>
            <OrganismController
              {...form.register('urlVideoModal')}
              control={form.control}
              label='Vídeo'
              name='urlVideoModal'
              placeholder='Insira o ID do vídeo (Spalla)'
              autoComplete='off'
            />
          </Col>
        </Row>
      )}

      {form.watch('mediaType') === 'IMAGE' && (
        <>
          <Row style={{ paddingTop: '24px' }}>
            <Col span={12} style={{ paddingRight: '12px' }}>
              <b>Imagem web</b>
            </Col>
            <Col span={12} style={{ paddingLeft: '12px' }}>
              <b>Imagem mobile</b>
            </Col>
          </Row>

          <Row style={{ paddingTop: '12px' }}>
            <Col span={12} style={{ paddingLeft: '12px' }}>
              <UploadBiggerController
                {...form.register('imageWeb')}
                control={form.control}
                name='imageWeb'
              />
            </Col>

            <Col span={12} style={{ paddingLeft: '12px' }}>
              <UploadBiggerController
                {...form.register('imageMobile')}
                control={form.control}
                name='imageMobile'
              />
            </Col>
          </Row>
        </>
      )}

      <Row style={{ paddingTop: '12px' }}>
        <Col span={12}>
          <OrganismController
            {...form.register('textButton')}
            name='textButton'
            control={form.control}
            label='Texto do botão'
            placeholder='Texto do botão'
            autoComplete='off'
            showCount
            maxLength={110}
          />
        </Col>

        <Col span={12} style={{ paddingLeft: '12px' }}>
          <OrganismController
            {...form.register('redirectButtonUrl')}
            control={form.control}
            label='Redirect do botão'
            name='redirectButtonUrl'
            placeholder='Insira a URL de redirecionamento para o Clique do Botão'
            autoComplete='off'
            prefix='https://'
          />
        </Col>
      </Row>

      <Row>
        <Col span={12} style={{ paddingRight: '12px' }}></Col>

        <Col span={12} style={{ paddingLeft: '12px' }}>
          <CheckboxController
            {...form.register('openExternalWindow')}
            control={form.control}
            name={'openExternalWindow'}
            defaultValue={false}
          >
            Abrir em uma página externa
          </CheckboxController>
        </Col>
      </Row>
    </Form>
  );
};
