import React from 'react';
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
  url: string;
  className?: string;
  height?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  className,
  height,
}) => {
  const isVimeoWithAppId = url.includes('player.vimeo.com/video/');

  const getIframeSrc = (videoUrl: string) => {
    const autoplayParam = videoUrl.includes('?')
      ? '&autoplay=1&background=1'
      : '?autoplay=1&background=1';
    const mutedParam = '&muted=1';
    return `${videoUrl}${autoplayParam}${mutedParam}`;
  };

  const renderPlayer = () => {
    if (isVimeoWithAppId) {
      return (
        <iframe
          src={`https://${getIframeSrc(url)}`}
          className={`video-iframe ${className || ''}`}
          width='100%'
          height={height || '360'}
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          title='Embedded Vimeo Video'
        ></iframe>
      );
    } else {
      return (
        <ReactPlayer
          url={url}
          className={`react-player ${className || ''}`}
          width='100%'
          height='100%'
          controls
          playing
        />
      );
    }
  };

  return <div>{renderPlayer()}</div>;
};

export default VideoPlayer;
