import {
  SpecialistFormData,
  SpecialistPayload,
  SpecialistResponse,
} from '../types/specialistPageTypings';

export const specialistFunnelsPagesFactory = (payload: SpecialistFormData) => {
  const body: SpecialistPayload = {
    cancellationFunnelId: Number(payload.cancellationFunnelId),
    pageType: 'specialist',
    name: payload.titlePage,
    content: {
      title: payload.title,
      subtitle: payload.subtitle,
      titleButtonEmail: payload.buttonTitle,
      Email: payload.email,
      titleButtonWhatsapp: payload.buttonTitleWhatsApp,
      whatsapp: payload.whatsApp,
      interaction: payload.interaction,
    },
  };

  return body;
};

export const specialistFunnelsPagesResponseFactory = (
  response: SpecialistResponse
) => {
  const body: SpecialistFormData = {
    titlePage: response.name,
    title: response.content.title,
    subtitle: response.content.subtitle,
    buttonTitle: response.content.titleButtonEmail,
    email: response.content.Email,
    buttonTitleWhatsApp: response.content.titleButtonWhatsapp,
    whatsApp: response.content.whatsapp,
    interaction: response.content.interaction,
    cancellationFunnelId: response.cancellationFunnelId,
  };

  return body;
};
