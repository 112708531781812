import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { ReleaseFormData } from '../../types/releasePageTypings';
import { useTryToRequestReleasePage } from './useTryToRequestReleasePage';
import { useTryToRequestUpdateReleasePage } from './useTryToRequestUpdateReleasePage';

interface useRequestReleasePageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  titlePage: string;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    slides: {
      title: string;
      subtitle: string;
      slidesImages: {
        web: string;
        mobile: string;
        link: string;
      }[];
    }[];
  }>;
}

export function useRequestReleasePage({
  isUpdate,
  setIsUpdate,
  setTitlePage,
  setValue,
  titlePage,
}: useRequestReleasePageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');
  const { tryToRequestReleasePage } = useTryToRequestReleasePage();
  const { isLoadingReleaseUpdate, tryToRequestUpdateReleasePage } =
    useTryToRequestUpdateReleasePage();

  const handleUpdateReleasePage = useCallback(
    async (e: Omit<ReleaseFormData, 'name'>) => {
      await tryToRequestUpdateReleasePage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    []
  );

  const handleRequestReleasePage = useCallback(async () => {
    await tryToRequestReleasePage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      response.slides.forEach((slide: any, slideIndex) => {
        const slidePrefix = `slides[${slideIndex}]`;

        Object.keys(slide).forEach(key => {
          if (key !== 'slidesImages') {
            const field = `${slidePrefix}.${key}`;
            const value = slide[key];
            setValue(field as any, value);
          } else {
            slide.slidesImages.forEach((image: any, imageIndex: number) => {
              const imagePrefix = `${slidePrefix}.slidesImages[${imageIndex}]`;
              Object.keys(image).forEach(imageKey => {
                const field = `${imagePrefix}.${imageKey}`;
                const value = image[imageKey];
                setValue(field as any, value);
              });
            });
          }
        });
      });
      setTitlePage(response.name);
    });
  }, []);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestReleasePage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate, handleRequestReleasePage]);

  return { handleUpdateReleasePage, isLoadingReleaseUpdate };
}
