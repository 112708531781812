import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../../api';
import { offerProductSellGroup } from '../../utils/offerFunnelsPagesFactory';

const fetchProductSellGroup = async (id: number) => {
  const response = await apiCancellationFunnels.tryToRequestProductSellGroup(
    id
  );

  if (response.status === 200) {
    const factory = offerProductSellGroup(response.data);
    return factory;
  }
  OpenNotification(
    true,
    'Erro ao carregar as ofertas',
    'Ocorreu um erro  ao carregar as ofertas, por favor tente novamente mais tarde.'
  );
  console.error(response);
  return undefined;
};

export function useTryToRequestOfferProduct(id: number) {
  const { data } = useQuery(
    ['product-sell-group'],
    () => fetchProductSellGroup(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  return { offerProduct: data };
}
