import { InboxOutlined } from '@ant-design/icons';
import { Form, Image, TooltipProps, Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Control,
  Controller as ControllerHookForm,
  useWatch,
} from 'react-hook-form';

import { toFieldStatus } from 'src/app/utils/toFieldStatus';

const { Item: FormItem } = Form;

interface UploadBiggerControllerProps extends UploadProps {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  required?: boolean;
  label?: string;
  tooltip?: ReactNode | (TooltipProps & { icon: ReactNode });
}

export const UploadBiggerController: React.FC<UploadBiggerControllerProps> = ({
  control,
  name,
  required,
  label,
  children,
  tooltip,
  ...rest
}) => {
  const [base64, setBase64] = useState<string>();

  const fileValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    if (fileValue?.file) {
      getBase64(fileValue.file.originFileObj, url => {
        setBase64(url);
      });
    }
  }, [fileValue]);

  return (
    <ControllerHookForm
      control={control}
      name={name}
      render={({ field: { ...field }, fieldState }) => {
        const { status, help } = toFieldStatus(fieldState.error);
        return (
          <FormItem
            validateStatus={status}
            help={help}
            label={label}
            {...(tooltip && { tooltip: tooltip })}
            {...(required && { required: required })}
          >
            {!field.value ? (
              <Upload.Dragger
                {...rest}
                {...field}
                beforeUpload={file => {
                  getBase64(file, urlString => {
                    setBase64(urlString);
                  });
                }}
                showUploadList={false}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  <p>
                    <b style={{ color: '#1668DC' }}>Clique para carregar </b>
                    <span>ou arraste e solte</span>
                  </p>
                </p>
                <p className='ant-upload-hint'>
                  .png, .jpg ou .gif 1280x720pixel (@2x)
                </p>
              </Upload.Dragger>
            ) : (
              <Image
                {...field}
                src={base64}
                className='ant-upload'
                width={'100%'}
                height={'153px'}
              />
            )}
          </FormItem>
        );
      }}
    />
  );
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
