import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

export const IconContainer = styled.div`
  font-size: 17px;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0;
`;

export const SubTitleContainer = styled.div`
  margin-left: 28px;
`;
export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.45);
`;
