import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';

import { apiCancellationFunnels } from '../api';
import { funnelsPagesFactory } from '../utils/funnelsPagesFactory';

const fetchFunnelsPages = async (id: string) => {
  const response = await apiCancellationFunnels.tryToRequestFunnelsPages(id);

  if (response.status === 200) {
    const data = funnelsPagesFactory(response.data);

    return data;
  }

  OpenNotification(
    true,
    'Erro ao carregar os funis',
    'Ocorreu um erro  ao carregar os funis, por favor tente novamente mais tarde.'
  );

  return [];
};

export function useTryToRequestFunnelsPages(id: string) {
  const { data, isLoading, refetch } = useQuery('funnelsPages', () =>
    fetchFunnelsPages(id)
  );

  return { funnelsPages: data, isLoading, funnelsPageRefetch: refetch };
}
