import {
  DeleteOutlined,
  PlusCircleOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, TextAreaController } from '@organisms';
import { Button, Col, Collapse, Divider, Typography } from 'antd';
import { Form, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { LayoutFunnelPage } from 'src/features/Funnel/Components/LayoutFunnelPage';
import { TitleFunnelPage } from 'src/features/Funnel/Components/TitleFunnelPage';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';
import { useRequestReleasePage } from 'src/features/Funnel/hooks/release/useRequestReleasePage';
import { useTryToRequestCreatingReleaseFunnelPage } from 'src/features/Funnel/hooks/release/useTryToRequestCreatingReleaseFunnelPage';
import { ParamasProps } from 'src/features/Funnel/types/FunnelEditTypings';
import { ReleaseFormData } from 'src/features/Funnel/types/releasePageTypings';

const slideImagesSchema = yup.object().shape({
  web: yup
    .mixed()
    .nullable()
    .required('A Versão Web é obrigatório')
    .test('is-filled', 'A Versão Web é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  mobile: yup
    .mixed()
    .nullable()
    .required('A Versão mobile é obrigatório')
    .test('is-filled', 'A Versão mobile é obrigatória', function (value) {
      return value !== null && value !== '';
    }),
  link: yup.string().required('O Link é obrigatório'),
});

const slidesSchema = yup.object().shape({
  title: yup.string().required('O Título é obrigatório'),
  subtitle: yup.string().required('O Subtítulo é obrigatório'),
  slidesImages: yup
    .array()
    .of(slideImagesSchema)
    .required('Informe ao menos uma imagem'),
});

const validationSchema = yup.object({
  slides: yup.array().of(slidesSchema).required('Informe ao menos um slide'),
});

export const FunnelReleasePageScreen: React.FC = () => {
  const [titlePage, setTitlePage] = useState('Modal de Lançamento');
  const [isUpdate, setIsUpdate] = useState(false);

  const history = useHistory();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFunnelId: '',
      slides: [
        {
          title: '',
          subtitle: '',
          slidesImages: [
            {
              web: '',
              mobile: '',
              link: '',
            },
            {
              web: '',
              mobile: '',
              link: '',
            },
            {
              web: '',
              mobile: '',
              link: '',
            },
          ],
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'slides' });

  const { id } = useParams<ParamasProps>();
  const { isLoadingCreatingRelease, tryToRequestCreatingReleaseFunnelPage } =
    useTryToRequestCreatingReleaseFunnelPage();
  const { isLoadingReleaseUpdate, handleUpdateReleasePage } =
    useRequestReleasePage({
      isUpdate,
      setIsUpdate,
      setTitlePage,
      setValue,
      titlePage,
    });

  const handleReleasePage = useCallback(
    async (e: Omit<ReleaseFormData, 'name'>) => {
      if (isUpdate) {
        return handleUpdateReleasePage(e);
      }
      await tryToRequestCreatingReleaseFunnelPage({ name: titlePage, ...e });
    },
    [isUpdate]
  );

  useEffect(() => {
    setValue('cancellationFunnelId', id);
  }, [id]);

  return (
    <LayoutFunnelPage>
      <TitleFunnelPage titlePage={titlePage} setTitlePage={setTitlePage} />
      <Row>
        <Form
          layout='vertical'
          requiredMark={'optional'}
          style={{
            width: '100%',
            gap: '24px',
            display: 'flex',
            flexDirection: 'column',
          }}
          onFinish={handleSubmit(handleReleasePage)}
        >
          <Collapse
            defaultActiveKey={[fields[0].id]}
            expandIconPosition={'end'}
          >
            {fields.map((field, index) => (
              <Collapse.Panel
                header={`Slide ${index + 1}`}
                key={field.id}
                extra={
                  <Button
                    type='text'
                    style={{
                      minWidth: 'auto',
                      padding: '1px 6px',
                    }}
                    onClick={() => remove(index)}
                    disabled={fields.length <= 1}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </Button>
                }
              >
                <Row style={{ flexDirection: 'column', gap: '24px' }}>
                  <Controller
                    control={control}
                    name={`slides[${index}].title`}
                    placeholder='Escreva o principal título da página.'
                    defaultValue={field.title}
                    autoComplete='off'
                    label='Título Principal'
                    showCount={true}
                    maxLength={250}
                    required
                  />
                  <TextAreaController
                    control={control}
                    name={`slides[${index}].subtitle`}
                    placeholder='Área de texto'
                    defaultValue={field.subtitle}
                    autoComplete='off'
                    label='Subtítulo'
                    isMaxLength={true}
                    maxLength={250}
                    row={2}
                    required={false}
                  />
                  {field.slidesImages.map((imageData, imageIndex) => (
                    <Row style={{ flexDirection: 'column' }}>
                      <Col
                        style={{
                          gap: '24px',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                        key={imageData.web}
                      >
                        <Col style={{ flex: 1 }}>
                          <UploadImage
                            tooltip={{
                              title: () => (
                                <Typography.Text>
                                  <strong>Versão Web</strong> - 482 x 282 pixels
                                  (jpg, jpeg, png)
                                </Typography.Text>
                              ),
                            }}
                            control={control}
                            name={`slides[${index}].slidesImages[${imageIndex}].web`}
                            label='Versão Web'
                            required
                          />
                        </Col>
                        <Col style={{ flex: 1 }}>
                          <UploadImage
                            tooltip={{
                              title: () => (
                                <Typography.Text>
                                  <strong>Versão App</strong> - 343 x 124 pixels
                                  (jpg, jpeg, png)
                                </Typography.Text>
                              ),
                            }}
                            control={control}
                            name={`slides[${index}].slidesImages[${imageIndex}].mobile`}
                            label='Versão Mobile'
                            required
                          />
                        </Col>
                      </Col>
                      <Controller
                        control={control}
                        name={`slides[${index}].slidesImages[${imageIndex}].link`}
                        placeholder='Link da imagem'
                        defaultValue={imageData.link}
                        autoComplete='off'
                        label='Link da imagem'
                        required
                        prefix={
                          <ThunderboltOutlined style={{ color: '#1668DC' }} />
                        }
                        showCount={true}
                        maxLength={250}
                      />
                    </Row>
                  ))}
                </Row>
              </Collapse.Panel>
            ))}
          </Collapse>
          <Divider orientation='center' style={{ marginTop: '398px' }} />
          <Row style={{ justifyContent: 'space-between' }}>
            <Col>
              <Button
                type='text'
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  append({
                    title: '',
                    subtitle: '',
                    slidesImages: [
                      {
                        web: '',
                        mobile: '',
                        link: '',
                      },
                      {
                        web: '',
                        mobile: '',
                        link: '',
                      },
                      {
                        web: '',
                        mobile: '',
                        link: '',
                      },
                    ],
                  });
                }}
                disabled={fields.length >= 5}
              >
                Adicionar mais uma opção
              </Button>
            </Col>
            <Row style={{ gap: '8px' }}>
              <Button type='default' onClick={() => history.goBack()}>
                Cancelar
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                loading={
                  isUpdate ? isLoadingReleaseUpdate : isLoadingCreatingRelease
                }
              >
                Salvar
              </Button>
            </Row>
          </Row>
        </Form>
      </Row>
    </LayoutFunnelPage>
  );
};
