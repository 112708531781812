import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useTryToRequestFunnelsPages } from '../hooks/useTryToRequestFunnelsPages';
import { useTryToRequestRemoveFunnelPage } from '../hooks/useTryToRequestRemoveFunnelPage';
import { ParamasProps } from '../types/FunnelEditTypings';

interface TableDropdownProps {
  item: {
    key: string;
    name: string;
    pageType:
      | 'Pergunta'
      | 'Mídia'
      | 'Oferta'
      | 'Funcionalidade'
      | 'Atendimento especialista'
      | 'Lançamento';
    status: string;
  };
}

interface RedirectionToEditFunnelPageProps {
  pageType:
    | 'Pergunta'
    | 'Mídia'
    | 'Oferta'
    | 'Funcionalidade'
    | 'Atendimento especialista'
    | 'Lançamento';
  pageId: string;
  funnelTypeId: string;
}

export const TableDropdown = ({ item }: TableDropdownProps) => {
  const { id: funnelTypeId } = useParams<ParamasProps>();
  const { funnelsPageRefetch } = useTryToRequestFunnelsPages(funnelTypeId);
  const { tryToRequestRemoveFunnelPage } = useTryToRequestRemoveFunnelPage();
  const history = useHistory();

  const handleRedirectionToEditFunnelPage = useCallback(
    ({ pageType, pageId, funnelTypeId }: RedirectionToEditFunnelPageProps) => {
      switch (pageType) {
        case 'Pergunta':
          return `/unsubscribe/funnel/question/${funnelTypeId}?pageId=${pageId}`;
        case 'Mídia':
          return `/unsubscribe/funnel/media/${funnelTypeId}?pageId=${pageId}`;
        case 'Oferta':
          return `/unsubscribe/funnel/offer/${funnelTypeId}?pageId=${pageId}`;
        case 'Funcionalidade':
          return `/unsubscribe/funnel/functionality/${funnelTypeId}?pageId=${pageId}`;
        case 'Atendimento especialista':
          return `/unsubscribe/funnel/specialist-care/${funnelTypeId}?pageId=${pageId}`;
        case 'Lançamento':
          return `/unsubscribe/funnel/release/${funnelTypeId}?pageId=${pageId}`;
        default:
          return '';
      }
    },
    []
  );

  const handleRemoveFunnelPage = useCallback(async () => {
    await tryToRequestRemoveFunnelPage(item.key).then(() => {
      funnelsPageRefetch();
    });
  }, [item]);

  return (
    <Dropdown
      trigger={['click']}
      arrow={false}
      overlay={() => {
        return (
          <Menu>
            <MenuItem
              key='edit'
              onClick={(e: any) => {
                e.domEvent.stopPropagation();
                const redirect = handleRedirectionToEditFunnelPage({
                  pageType: item.pageType,
                  pageId: item.key,
                  funnelTypeId: funnelTypeId,
                });
                history.push(redirect);
              }}
            >
              <EditOutlined style={{ marginRight: '12px' }} /> Editar
            </MenuItem>
            <MenuItem
              key='delete'
              onClick={(e: any) => {
                e.domEvent.stopPropagation();
                handleRemoveFunnelPage();
              }}
              style={{ color: '#E84749' }}
            >
              <DeleteOutlined
                style={{ marginRight: '12px', color: '#E84749' }}
              />{' '}
              Deletar
            </MenuItem>
          </Menu>
        );
      }}
    >
      <ButtomMenu type='text'>
        <MoreOutlined />
      </ButtomMenu>
    </Dropdown>
  );
};

const ButtomMenu = styled(Button)`
  &:focus {
    color: #3c89e8 !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  color: #acacac;

  ${props =>
    props.disabled &&
    css`
      color: rgba(255, 255, 255, 0.25) !important;
    `}
`;
