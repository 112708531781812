import { ArrowLeftOutlined } from '@ant-design/icons';
import { EmptyButton } from '@atoms';
import { Divider } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './PageHeader.style';

interface PageHeaderProps {
  backToPath: string | (() => void);
  title: string;
  subTitle: string;
}
export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subTitle,
  backToPath,
}) => {
  const history = useHistory();
  const backTo = () => {
    if (typeof backToPath === 'string') {
      history.push(backToPath);
    }

    if (typeof backToPath === 'function') {
      backToPath();
    }
  };
  return (
    <S.Container style={{ width: '100%' }}>
      <S.TitleContainer>
        <S.IconContainer>
          <EmptyButton onClick={backTo}>
            <ArrowLeftOutlined />
          </EmptyButton>
        </S.IconContainer>
        <S.Title>{title}</S.Title>
      </S.TitleContainer>
      <S.SubTitleContainer>
        <S.SubTitle>{subTitle}</S.SubTitle>
      </S.SubTitleContainer>

      <Divider />
    </S.Container>
  );
};
