import { slices } from '@core/redux';
import { coupons } from '@service/api';
// import { coupons } from '@service/api';
import { PageLimitFilter } from '@typings';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';
import { TCouponResponse } from 'src/models/Coupon.model';

import OpenNotification from '../components/antd/OpenNotification';
import { TableCouponProps } from '../framework/pages/Coupons/Table.coupons';

export const useCoupon = () => {
  const dispatch = useDispatch();

  const [couponList, setCouponList] = useState<TCouponResponse>();

  const [currentFilter, setCurrentFilter] = useState<PageLimitFilter>();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isSelectAddCouponModalOpen, setIsSelectAddCouponModalOpen] =
    useState(false);

  const handleOpenModalCoupon = useCallback(
    () => setIsSelectAddCouponModalOpen(true),
    []
  );

  const handleCloseModalCoupon = useCallback(
    () => setIsSelectAddCouponModalOpen(false),
    []
  );

  const getAllCoupons = useCallback(async (props?: PageLimitFilter) => {
    try {
      setIsLoadingData(true);
      dispatch(slices.layout.increaseLoading());
      if (props) setCurrentFilter(props);

      if (!props?.limit || !props.page) {
        props = {
          ...props,
          limit: 0,
          page: 1,
        };
      }
      const response = await coupons.getAllCoupon(props);
      setCouponList(response);
    } catch (err) {
      console.error(err);
      OpenNotification(true, 'Falha ao carregar os cupons do sistema.');
    } finally {
      dispatch(slices.layout.decreaseLoading());
      setIsLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getAllCoupons();
  }, []);

  useBreadcrumbs([
    {
      label: 'Gestão de Cupons',
    },
  ]);

  const handleChangePagination: TableCouponProps['onChangePagination'] = (
    take,
    _,
    pagination
  ) => {
    getAllCoupons({
      ...currentFilter,
      limit: take,
      page: pagination,
    });
  };

  const handleFilterCoupon = useCallback(
    (filterList: { field: string; value?: string }[]) => {
      const current = {
        ...currentFilter,
        filter: {
          ...currentFilter?.filter,
        },
      };

      filterList.forEach(filterToAdd => {
        if (!filterToAdd.value) {
          delete current.filter?.[filterToAdd.field];
        } else {
          current.filter[filterToAdd.field] = filterToAdd.value;
        }
      });

      getAllCoupons(current as PageLimitFilter);
    },
    [getAllCoupons, currentFilter]
  );

  const handleOrderCoupon = useCallback(
    (order: string) => {
      getAllCoupons({ ...currentFilter, order });
    },
    [getAllCoupons, currentFilter]
  );

  return {
    handleChangePagination,
    couponList,
    handleFilterCoupon,
    isLoadingData,
    handleOpenModalCoupon,
    handleCloseModalCoupon,
    isSelectAddCouponModalOpen,
    handleOrderCoupon,
  };
};
