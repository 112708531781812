import { Modal } from '@models';
import { LayoutConfirmationFooter } from '@molecules';
import { PageHeader } from '@organisms';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { useModalDetail } from '../../../hooks/useModalDetail';
import { ModalDetailHandle } from './ModalDetail.handler';
import { ModalDetailSteps } from './ModalDetail.steps';

export const ModalDetailPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const {
    modelForm,
    defaultForm,
    highlightForm,
    paginationForm,
    schedulingForm,
    getCurrentContentForm,
    saveModal,
    updateModal,
  } = useModalDetail();

  useBreadcrumbs([
    {
      label: 'Modal',
    },
  ]);
  const history = useHistory();

  const { state } = useLocation<Modal.IModal>();

  useEffect(() => {
    if (state?.id) {
      modelForm.setValue('modalType', state.type, { shouldValidate: true });
      modelForm.setValue('segment', state.modalSegments[0].segmentId, {
        shouldValidate: true,
      });

      schedulingForm.setValue('activated', state.activated, {
        shouldValidate: true,
      });
      schedulingForm.setValue('effectiveDateTime', state.effectiveDateTime, {
        shouldValidate: true,
      });
      schedulingForm.setValue('expirationDateTime', state.expirationDateTime, {
        shouldValidate: true,
      });

      const customTypeForm = getCurrentContentForm();

      if (state.type === Modal.TypeEnum.PAGINATION) {
        customTypeForm.setValue('urlLocalShow', state.displayScreenLink || '', {
          shouldValidate: true,
        });
        customTypeForm.setValue(
          'modalContent',
          state.advertisements.map(
            adversementItem => {
              let complemet = {};
              const mediaType = !!adversementItem.videoLink ? 'VIDEO' : 'IMAGE';

              if (mediaType === 'VIDEO' && !adversementItem.videoLink) {
                complemet = {
                  urlVideoModal: undefined,
                };
              }

              if (adversementItem.videoLink) {
                complemet = {
                  urlVideoModal: adversementItem.videoLink,
                };
              }

              if (mediaType === 'IMAGE' && !adversementItem.webImageFileId) {
                customTypeForm.setError('imageWeb', { message: 'Campo' });
                complemet = {
                  imageWeb: undefined,
                };
              }

              if (mediaType === 'IMAGE' && !adversementItem.mobileImageFileId) {
                complemet = {
                  imageMobile: undefined,
                };
              }

              if (adversementItem.webImageFileId) {
                complemet = {
                  imageWeb: adversementItem.webImageFileId,
                };
              }

              if (adversementItem.mobileImageFileId) {
                complemet = {
                  imageMobile: adversementItem.mobileImageFileId,
                };
              }

              return {
                title: adversementItem.title,
                description: adversementItem.description,
                mediaType: !!adversementItem.videoLink ? 'VIDEO' : 'IMAGE',
                redirectButtonUrl: adversementItem.buttonActionLink || '',
                textButton: adversementItem.buttonLabel || '',
                openExternalWindow: !!adversementItem?.openExternalWindow,
                ...complemet,
              };
            },
            { shouldValidate: true }
          )
        );
      } else {
        customTypeForm.setValue('title', state.advertisements[0].title, {
          shouldValidate: true,
        });
        customTypeForm.setValue(
          'description',
          state.advertisements[0].description,
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'mediaType',
          state.advertisements[0].webImageFileId &&
            state.advertisements[0].mobileImageFileId
            ? 'IMAGE'
            : 'VIDEO',
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'redirectButtonUrl',
          state.advertisements[0].buttonActionLink || '',
          { shouldValidate: true }
        );
        customTypeForm.setValue(
          'textButton',
          state.advertisements[0].buttonLabel || '',
          { shouldValidate: true }
        );

        if (
          !state.advertisements[0].videoLink &&
          customTypeForm.getValues('mediaType') === 'VIDEO'
        ) {
          customTypeForm.setValue('urlVideoModal', undefined, {
            shouldValidate: true,
          });
        }

        if (state.advertisements[0].videoLink) {
          customTypeForm.setValue(
            'urlVideoModal',
            state.advertisements[0].videoLink || undefined,
            { shouldValidate: true }
          );
        }

        if (
          !state.advertisements[0].webImageFileId &&
          customTypeForm.getValues('mediaType') === 'IMAGEM'
        ) {
          customTypeForm.setValue('imageWeb', undefined, {
            shouldValidate: true,
          });
        }

        if (
          !state.advertisements[0].mobileImageFileId &&
          customTypeForm.getValues('mediaType') === 'IMAGEM'
        ) {
          customTypeForm.setValue('imageMobile', undefined, {
            shouldValidate: true,
          });
        }

        if (state.advertisements[0]?.webImageFileId) {
          customTypeForm.setValue(
            'imageWeb',
            state.advertisements[0].webImageFileId,
            { shouldValidate: true }
          );
          customTypeForm.setValue(
            'imageMobile',
            state.advertisements[0].mobileImageFileId,
            { shouldValidate: true }
          );
        }
      }
    }
  }, [state]);

  const getIfAbleToNext = (step?: number): boolean => {
    const verifyStep = step || currentStep;
    if (verifyStep === 0) {
      return modelForm.formState.isValid;
    }

    if (verifyStep === 1) {
      const formInstance: UseFormReturn<
        Modal.IDefaultContent | Modal.IPaginationContent
      > = getCurrentContentForm();
      return formInstance.formState.isValid;
    }

    if (verifyStep === 2) {
      return schedulingForm.formState.isValid;
    }

    return false;
  };

  const handleOnSave = async () => {
    const hasAble = getIfAbleToNext();
    const formInstance: UseFormReturn<
      Modal.IDefaultContent | Modal.IPaginationContent
    > = getCurrentContentForm();

    if (currentStep === 0) {
      modelForm.handleSubmit(() => null);
    }

    if (currentStep === 1) {
      formInstance.trigger();
    }

    if (currentStep === 2) {
      schedulingForm.trigger();
    }

    if (!hasAble) return;
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
      return;
    }

    if (currentStep === 2) {
      if (state?.id) {
        await updateModal(state.id);
      } else {
        await saveModal();
      }
      history.push('/screens/modals');
    }
  };

  const handleChangeStep = async (value: number) => {
    const hasAble = getIfAbleToNext(currentStep);

    if (value < currentStep) {
      setCurrentStep(value);
      return;
    }
    if (hasAble) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleCancelForm = () => {
    history.push('/screens/modals');
  };

  return (
    <LayoutConfirmationFooter
      ableToSave={getIfAbleToNext()}
      onSave={handleOnSave}
      ableToSubmitDisabled
      onCancel={handleCancelForm}
      continueLabelButton={currentStep !== 3 ? 'Continuar' : undefined}
    >
      {state?.id ? (
        <PageHeader
          backToPath='/screens/modals'
          title='Editar Modal'
          subTitle='Edite um modal definindo seu segmento, tipo, conteúdo e agendamento.'
        />
      ) : (
        <PageHeader
          backToPath='/screens/modals'
          title='Novo Modal'
          subTitle='Crie um novo modal definindo seu segmento, tipo, conteúdo e agendamento.'
        />
      )}

      <ModalDetailSteps
        currentStep={currentStep}
        setCurrentStep={handleChangeStep}
      />

      <div style={{ marginTop: '24px' }} />

      <ModalDetailHandle
        scheduleForm={schedulingForm}
        currentStep={currentStep}
        modelModalForm={modelForm}
        highLightModalForm={highlightForm}
        paginationForm={paginationForm}
        defaultModalForm={defaultForm}
      />
    </LayoutConfirmationFooter>
  );
};
