/* eslint-disable @typescript-eslint/no-empty-function */
import { Coupon } from '@models';
import { ActionsDropdown } from '@molecules';
import { PageListProps, PageListTemplate } from '@templates';
import { TableColumnType, Tag } from 'antd';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ICoupon, TCouponResponse, TypeEnum } from 'src/models/Coupon.model';

import {
  getInfoStatus,
  parseOrderFilter,
  tableColumnOrderDefined,
} from './utils/table';

export interface TableCouponProps {
  filterComponent: React.ReactNode;
  data?: TCouponResponse;
  onChangePagination?: (take: number, skip: number, pagination: number) => void;
  onEditCoupon?: (value: Coupon.ICoupon) => void;
  onDeleteCoupon?: (value: Coupon.ICoupon) => void;
  onPreviewCoupon?: (value: Coupon.ICoupon) => void;
  onChangeOrder?: (order: string) => void;
  isLoading: boolean;
}

export type TColumnOrder = {
  column: string;
  inverse: boolean;
};

export const TableCoupon: React.FC<TableCouponProps> = ({
  filterComponent,
  data,
  onChangePagination,
  isLoading,
  onChangeOrder,
}) => {
  const [orderColumn, setOrderColumn] = useState<TColumnOrder | null>(null);
  const history = useHistory();

  const handleChangeOrder = useCallback(
    ({ key }: Pick<TableColumnType<Coupon.ICoupon>, 'key'>) => {
      if (!key) return;

      const column = parseOrderFilter(String(key));

      if (!orderColumn || orderColumn.column !== column) {
        setOrderColumn({ column, inverse: false });
        return;
      }

      if (orderColumn.inverse) {
        setOrderColumn(null);
        return;
      }

      setOrderColumn({ column, inverse: true });
    },
    [orderColumn]
  );

  useEffect(() => {
    if (!onChangeOrder || isLoading || !data) return;
    onChangeOrder(
      !orderColumn
        ? ''
        : `[${orderColumn.column},${orderColumn.inverse ? 'DESC' : 'ASC'}]`
    );
  }, [orderColumn]);

  const handleViewCoupon = (coupon: ICoupon) => {
    history.push({
      pathname: '/screens/coupons/read',
      state: { coupon },
    });
  };

  const headerColumns: PageListProps<Coupon.ICoupon>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
      render: (coupon: Coupon.ICoupon['id']) => {
        return coupon;
      },
    },
    {
      title: 'Código do Cupom',
      dataIndex: 'code',
      key: 'code',
      width: '200px',
      render: (code: Coupon.ICoupon['code']) => {
        return code;
      },
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: '260px',
      render: (description: Coupon.ICoupon['description']) => {
        return description;
      },
    },
    {
      title: 'Tipo de Desconto',
      dataIndex: 'discount',
      key: 'discount',
      width: '150px',
      render: (discount: Coupon.ICoupon['discount']) => {
        return <div>{Coupon.getLabelType(discount.type)}</div>;
      },
    },
    {
      title: 'Valor',
      dataIndex: 'discount',
      key: 'discount',
      width: '150px',
      render: (discount: Coupon.ICoupon['discount']) => {
        return discount.type === TypeEnum.FIXED
          ? new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(discount.value)
          : `${discount.value}%`;
      },
    },
    {
      title: 'Data de início',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '156px',
      sorter: true,
      sortOrder: tableColumnOrderDefined('startDate', orderColumn),
      onHeaderCell: columns => {
        return {
          onClick: () => {
            handleChangeOrder(columns);
          },
        };
      },
      render: (startDate: Coupon.ICoupon['startDate']) => {
        // return startDate;
        return <div>{format(new Date(startDate), 'dd/MM/yyyy')}</div>;
      },
    },
    {
      title: 'Data Final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: '156px',
      sorter: true,
      sortOrder: tableColumnOrderDefined('endDate', orderColumn),
      onHeaderCell: columns => {
        return {
          onClick: () => {
            handleChangeOrder(columns);
          },
        };
      },
      render: (endDate: Coupon.ICoupon['endDate']) => {
        // return endDate;
        return <div>{format(parseISO(endDate), 'dd/MM/yyyy')}</div>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '150px',
      render: (status: Coupon.ICoupon['status']) => {
        const { Icon, color, text } = getInfoStatus(status);

        return (
          <Tag icon={<Icon />} color={color}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'index',
      width: '2%',
      render: (_text, record) => (
        <ActionsDropdown
          hideDelete
          onClickEditOption={() => {
            history.push(`/screens/coupons/${record.id}`);
          }}
          onClickPreviewOption={() => {
            handleViewCoupon(record);
          }}
        />
      ),
    },
  ];

  if (!data && isLoading) return null;

  return (
    <PageListTemplate
      filterComponent={filterComponent}
      columns={headerColumns}
      data={data}
      loading={isLoading}
      onChange={onChangePagination}
    />
  );
};
