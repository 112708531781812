import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import { ModalOverlay } from '@atoms';
import { Modal } from '@models';
import { CustomSwitch } from '@molecules';
import { PropsWithChildren, useState } from 'react';

import * as S from './Template.style';
import { Default } from './templates/Default';
import { DefaultMobile } from './templates/DefaultMobile';
import { HighliteMobile } from './templates/HighlightMobile';
import { Highlite } from './templates/Highlite';
import { Pagination } from './templates/Pagination';
import { PaginationMobile } from './templates/PaginationMobile';

interface BaseTemplateProps {
  onClose: () => void;
  setSwitchValue?: React.Dispatch<React.SetStateAction<number>>;
  switchValue?: number;
  modalInfo?: Modal.IModal;
}

const Template = ({
  children,
  setSwitchValue,
  switchValue,
}: PropsWithChildren<BaseTemplateProps>) => {
  return (
    <ModalOverlay>
      <S.Container>
        <CustomSwitch
          activeIndex={switchValue || 0}
          onChange={value => setSwitchValue?.(value)}
          LeftIcon={<DesktopOutlined />}
          RightIcon={<MobileOutlined />}
        />

        {children}
      </S.Container>
    </ModalOverlay>
  );
};

export default Template;

function DefaultTemplate(props: BaseTemplateProps) {
  const [switchValue, setSwitchValue] = useState(0);

  const ComponentToRender = switchValue === 0 ? Default : DefaultMobile;
  return (
    <Template
      {...props}
      setSwitchValue={setSwitchValue}
      switchValue={switchValue}
    >
      <ComponentToRender onClose={props.onClose} modalInfo={props.modalInfo} />
    </Template>
  );
}
Template.Default = DefaultTemplate;

function HighlightTemplate(props: BaseTemplateProps) {
  const [switchValue, setSwitchValue] = useState(0);

  const ComponentToRender = switchValue === 0 ? Highlite : HighliteMobile;
  return (
    <Template
      {...props}
      setSwitchValue={setSwitchValue}
      switchValue={switchValue}
    >
      <ComponentToRender onClose={props.onClose} modalInfo={props.modalInfo} />
    </Template>
  );
}
Template.Highlight = HighlightTemplate;

function PaginationTemplate(props: BaseTemplateProps) {
  const [switchValue, setSwitchValue] = useState(0);

  const ComponentToRender = switchValue === 0 ? Pagination : PaginationMobile;
  return (
    <Template
      {...props}
      setSwitchValue={setSwitchValue}
      switchValue={switchValue}
    >
      <ComponentToRender onClose={props.onClose} modalInfo={props.modalInfo} />
    </Template>
  );
}
Template.Pagination = PaginationTemplate;
