import { Tabs } from 'antd';
import { FC } from 'react';

const { TabPane } = Tabs;

export type TabComponentProps = {
  tabList: string[];
  onChangeTab: (item: string) => void;
};

export const TabComponent: FC<TabComponentProps> = ({
  tabList,
  onChangeTab,
}) => {
  return (
    <div>
      <Tabs defaultActiveKey='0' onChange={onChangeTab}>
        {tabList.map((item, index) => (
          <TabPane tab={item} key={index}></TabPane>
        ))}
      </Tabs>
    </div>
  );
};
