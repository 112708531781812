import { env } from 'src/app/env';

import { Offer } from '../interfaces/offersInterface';

export const formOfferFactory = (
  data: Offer,
  definePlanType: (arg0?: string) => string | undefined
) => {
  return {
    offerName: data.name,
    offerDescription: data.description,
    bannerWeb:
      data.checkout?.bannerWeb && `${env.ASSETS}${data.checkout?.bannerWeb}`,
    bannerMobile:
      data.checkout?.bannerMobile &&
      `${env.ASSETS}${data.checkout?.bannerMobile}`,
    bannerSideWeb:
      data.checkout?.bannerSideWeb &&
      `${env.ASSETS}${data.checkout?.bannerSideWeb}`,
    logo: data.checkout?.logo && `${env.ASSETS}${data.checkout?.logo}`,
    fullPrice: data.fullPrice.toString(),
    installments: data.installments.toString(),
    startDate: data.startDate,
    endDate: data?.endDate,
    purchaseBtnLabel: data.checkout?.purchaseBtnLabel,
    googleAnalyticsIds: data.checkout?.googleAnalyticsIds,
    facebookPixels: data.checkout?.facebookPixels,
    privacyPolicyRef: data.checkout?.privacyPolicyRef,
    termsRef: data.checkout?.termsRef,
    plan: definePlanType(data?.accessProfileId ?? undefined),
    offerStandart: data?.replaceDefaultOffer,
    hasCounter: data.checkout?.enableCounter,
    rightImg: data.checkout?.bannerRightMobileCounter,
    rightImgMobile: data.checkout?.bannerRightMobileCounter,
    leftImg: data.checkout?.bannerLeftCounter,
    leftImgMobile: data.checkout?.bannerLeftMobileCounter,
    bgCounter: data.checkout?.colorBackgroundCounter,
  };
};
