import { useModalOrdering } from '@hooks';
import { Modal } from '@models';
import { LayoutConfirmationFooter, OrderTableModals } from '@molecules';
import { PageHeader } from '@organisms';
import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

import { FilterOrdering } from './Filter.modalOrdering';
import * as S from './ModalOrdering.style';

export const ModalsOrdering: React.FC = () => {
  const [currentCollapse, setCurrentCollapse] = useState('');

  const [filterBysegment, setFilterBysegment] = useState<number>();

  useBreadcrumbs([
    {
      label: 'Ordenação de modais',
    },
  ]);

  const {
    getOrderingModals,
    modalOrderingList,
    saveSort,
    orderignModal,
    setOrderingModal,
  } = useModalOrdering();

  const history = useHistory();

  useEffect(() => {
    getOrderingModals();
  }, []);

  const handleFilterModal = (
    filterList: { field: string; value?: string }[]
  ) => {
    const current: any = {
      filter: {},
    };

    filterList.forEach(filterToAdd => {
      if (!filterToAdd.value) {
        delete current.filter?.[filterToAdd.field];
      } else {
        current.filter[filterToAdd.field] = filterToAdd.value;
      }
    });

    getOrderingModals(current);
  };

  const orderSort = (a: Modal.OrderingModal, b: Modal.OrderingModal) => {
    if (a.order === null) return 1;
    if (b.order === null) return -1;
    return a.order - b.order;
  };

  const titleSort = (a: Modal.OrderingModal, b: Modal.OrderingModal) => {
    const titleA = a.modal.advertisements[0]?.title || '';
    const titleB = b.modal.advertisements[0]?.title || '';
    return titleA.localeCompare(titleB);
  };

  return (
    <LayoutConfirmationFooter
      ableToSave={!!orderignModal?.length}
      onSave={() => saveSort(orderignModal)}
      onCancel={() => history.push('/dashboard')}
    >
      <PageHeader
        backToPath='/dashboard'
        title='Ordenar Modais'
        subTitle='Ordene os Modais dentro de cada módulo de acordo com a sua prioridade.'
      />

      <S.FilterContainer>
        <FilterOrdering
          filter={filterBysegment}
          setFilter={value => setFilterBysegment(value)}
          onFilter={value => handleFilterModal(value)}
        />
      </S.FilterContainer>

      {Object.keys(modalOrderingList).map(pageName => (
        <S.CollapseContainer>
          <Collapse
            accordion
            onChange={key => setCurrentCollapse(key as string)}
            activeKey={currentCollapse}
          >
            <Collapse.Panel header={pageName} key={pageName.toUpperCase()}>
              <OrderTableModals
                columnTitle='Banners'
                changeOrder={order =>
                  setOrderingModal(order as Modal.OrderingModal[])
                }
                datakey='name'
                datasource={
                  !!filterBysegment
                    ? modalOrderingList[pageName].sort(orderSort)
                    : modalOrderingList[pageName].sort(titleSort)
                }
                showHeader={false}
                disableOrder={!filterBysegment}
              />
            </Collapse.Panel>
          </Collapse>
        </S.CollapseContainer>
      ))}
    </LayoutConfirmationFooter>
  );
};
