import { useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { useQueryParams } from 'src/app/hooks/useQueryParams';

import { OfferFormData } from '../../types/offerPageTypings';
import { useTryToRequestOfferPage } from './useTryToRequestOfferPage';
import { useTryToRequestUpdateOfferPage } from './useTryToRequestUpdateOfferPage';

interface UseRequestOfferPageProps {
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    slides: {
      title: string;
      offer: string;
      web: string;
      mobile: string;
      imageTitle: string;
      subtitle: string;
      description: string;
      oldValue: number;
      finalValue: string;
      textValue: string;
      buttonTitle: string;
      buttonLink: string;
      isTarget: boolean;
    }[];
  }>;
}

export function useRequestOfferPage({
  isUpdate,
  setIsUpdate,
  setTitlePage,
  setValue,
}: UseRequestOfferPageProps) {
  const params = useQueryParams();
  const pageId = params.get('pageId');

  const { tryToRequestOfferPage } = useTryToRequestOfferPage();
  const { tryToRequestUpdateOfferPage, isLoadingUpdateOffer } =
    useTryToRequestUpdateOfferPage();

  const handleUpdateOfferPage = useCallback(
    async (e: Omit<OfferFormData, 'name'>, titlePage: string) => {
      await tryToRequestUpdateOfferPage({
        id: pageId as string,
        payload: { name: titlePage, ...e },
      });
    },
    [tryToRequestUpdateOfferPage, pageId]
  );

  const handleRequestOfferPage = useCallback(async () => {
    await tryToRequestOfferPage(pageId as string).then(response => {
      setValue('cancellationFunnelId', response.cancellationFunnelId);
      setTitlePage(response.name);
      response.slides.forEach((slide: any, index) => {
        const slidePrefix = `slides[${index}]`;

        Object.keys(slide).forEach(key => {
          const field = `${slidePrefix}.${key}`;
          const value = slide[key];
          setValue(field as any, value);
        });
      });
    });
  }, [tryToRequestOfferPage, setValue, setTitlePage]);

  useEffect(() => {
    if (pageId && !isUpdate) {
      handleRequestOfferPage();
      setIsUpdate(true);
    }
  }, [pageId, isUpdate]);

  return { handleUpdateOfferPage, isLoadingUpdateOffer };
}
