import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { FC, useState } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { customerAdyenReportApi } from 'src/features/clients/api/customerAdyenReportApi';
import { IAdyenRefundReportApiDto } from 'src/features/clients/types/adyenRefundApiReportDto';
import {
  AdyenTransaction,
  ClientType,
} from 'src/features/clients/types/clientTypes';
import { customerAdyenRefundPdf } from 'src/features/clients/utils/customerAdyenReportUtils';

import { ModalRefund } from '../../components/ModalRefund';

export type TableProps = {
  transactionsAdyen?: AdyenTransaction[];
  dados?: ClientType;
};

export const TransactionsTabsComponent: FC<TableProps> = ({
  transactionsAdyen,
  dados,
}) => {
  const [isModalVisibleRefund, setIsModalVisibleRefund] = useState({
    itemId: 0,
    visible: false,
    amount: 0,
    gatewayTransaction: '',
    document: '',
    email: '',
  });

  const downloadReport = async (reportIdentifier?: string) => {
    if (reportIdentifier && dados?.email) {
      const response = await customerAdyenReportApi.adyenRefundReportData(
        reportIdentifier,
        dados.email
      );
      customerAdyenRefundPdf((response as IAdyenRefundReportApiDto).data.data);
    }
  };

  const DropdownComponent = (item: AdyenTransaction) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                data-testid='reembolso'
                onClick={() =>
                  setIsModalVisibleRefund({
                    itemId: item.billingId,
                    visible: true,
                    amount: Number(item.amount),
                    gatewayTransaction: item.gatewayTransaction,
                    document: dados?.document ?? '',
                    email: dados?.email ?? '',
                  })
                }
                key='reembolso'
              >
                Reembolso
              </Menu.Item>
              {item.hasReport && (
                <Menu.Item
                  data-testid='report'
                  onClick={() => downloadReport(item.reportIdentifier)}
                  key='reembolso_report'
                >
                  Relatório
                </Menu.Item>
              )}
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  return (
    <div>
      <Table
        scroll={{ x: '100% ' }}
        style={{ width: '100vw' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Transação',
            dataIndex: 'gatewayTransaction',
          },
          {
            title: 'Status ',
            dataIndex: 'status',
          },
          {
            title: 'Tipo',
            dataIndex: 'paymentMethod',
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: (amount: number) => <>{convertToCurrency(amount)}</>,
          },
          {
            title: 'Data da compra',
            dataIndex: 'paymentDate',
            render: (paymentDate: string) => (
              <div>{dateFormatted(paymentDate, true)}</div>
            ),
          },
          {
            title: 'ID da Assinatura ',
            dataIndex: 'subscriptionId',
          },
          {
            title: 'Ações',
            key: 'action',
            render: (item: AdyenTransaction) => DropdownComponent(item),
          },
        ]}
        dataSource={transactionsAdyen}
      />
      <ModalRefund
        modalValues={isModalVisibleRefund}
        downloadReport={downloadReport}
        setIsModalVisible={() =>
          setIsModalVisibleRefund({
            itemId: 0,
            visible: false,
            amount: 0,
            gatewayTransaction: '',
            document: '',
            email: '',
          })
        }
      />
    </div>
  );
};
