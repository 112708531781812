import { Modal } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useBreadcrumbs } from 'src/features/layout/hooks/useBreadcrumbs';

export const useCoupon = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  useBreadcrumbs([
    {
      label: 'Gestão de Cupons',
    },
    {
      label: 'Produto físico',
    },
    {
      label: 'Criação',
    },
  ]);

  const dispatchSubmit = useCallback(() => {
    formRef.current?.submit();
  }, [formRef.current]);

  const onSaveCoupon = useCallback(() => {
    Modal.success({
      title: 'Concluído!',
      content: 'Cupom salvo com sucesso!',
      onOk: () => {
        // history.push('/screens/coupons');
      },
      onCancel: () => {
        // history.push('/screens/coupons');
      },
    });
    history.push('/screens/coupons');
  }, [history]);

  const handleCancel = useCallback(() => {
    history.push('/screens/coupons');
  }, [history]);

  return {
    dispatchSubmit,
    onSaveCoupon,
    handleCancel,
    formRef,
    isLoading,
    setIsLoading,
  };
};
