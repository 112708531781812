import { base } from '@service/base';

import { ICoupon } from 'src/models/Coupon.model';

export async function getCouponById(couponId: number): Promise<ICoupon> {
  try {
    const { data } = await base.get<ICoupon>(`/sales/coupon/${couponId}`);
    return data;
  } catch (error) {
    throw new Error('Não foi possível carregar o cupom solicitado!');
  }
}
