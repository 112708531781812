import { base } from '@service/base';

import {
  PurchaseTypeAllowedEnum,
  TypeEnum,
} from '../../../models/Coupon.model';

export type TCreateCouponProps = {
  code: string;
  description: string;
  startDate: string;
  endDate: string;
  purchaseTypeAllowed: PurchaseTypeAllowedEnum;
  allowedProducts: number[];
  discount: {
    type: TypeEnum;
    value: number;
  };
  maxUse: number;
  maxUsePerCustomer: number;
  active: boolean;
};

export async function createCoupon(data: TCreateCouponProps) {
  const response = await base.post('/sales/coupon', data);

  if (!response.data) {
    throw new Error('Não foi possível carregar os dados');
  }

  return response.data;
}
