import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import React from 'react';

import { ActionsDropdownProps } from './ActionsDropdown.interface';

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  onClickEditOption,
  onClickDeleteOption,
  onClickPreviewOption,
  hideDelete = false,
  deleteLabel,
  disabled,
}) => {
  const handleClick: MenuProps['onClick'] = e => {
    switch (e.key) {
      case 'EDIT':
        onClickEditOption();
        break;
      case 'DELETE':
        onClickDeleteOption?.();
        break;

      case 'PREVIEW':
        onClickPreviewOption?.();
        break;
      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'PREVIEW',
      label: 'Visualizar',
      icon: <EyeOutlined />,
      style: { display: onClickPreviewOption ? 'inherit' : 'none' },
      disabled,
    },
    {
      key: 'EDIT',
      label: 'Editar',
      icon: <EditOutlined />,
      disabled,
    },
    hideDelete
      ? null
      : {
          key: 'DELETE',
          label: `Apagar`,
          icon: <DeleteOutlined />,
          danger: !disabled,
          disabled,
        },
  ];

  return (
    <Dropdown
      disabled={disabled}
      placement='bottomRight'
      menu={{ items, onClick: handleClick }}
      trigger={['hover']}
    >
      <Button
        disabled={disabled}
        data-testid='menu-test'
        style={{ border: 'none' }}
      >
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

export type { ActionsDropdownProps };
