import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { EmptyButton } from '@atoms';
import { Modal } from '@models';
import { Controller as OrganismController } from '@organisms';
import { Button, Col, Collapse, Form, Row } from 'antd';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ModelStepBaseComponentProp } from '../ModalDetail';
import { BaseContent } from './BaseContent.component';

const BASE_PATH =
  (process.env.REACT_APP_BASE_URL || '').indexOf('apidev') === -1
    ? 'https://play.queimadiaria.com'
    : 'https://dev.queimadiaria.com';

export const PaginationContent = ({
  form,
}: ModelStepBaseComponentProp<Modal.IPaginationContent>) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'modalContent',
  });

  useEffect(() => {
    form.setValue('urlLocalShow', `${BASE_PATH}/home`, {
      shouldValidate: true,
    });
    if (fields.length <= 0) {
      append({});
    }
  }, []);

  return (
    <>
      <Form layout='vertical'>
        <Row>
          <Col span={24}>
            <OrganismController
              {...form.register('urlLocalShow')}
              control={form.control}
              label='Local para exibição do modal'
              placeholder='Insira a URL onde o modal será exibido'
              autoComplete='off'
              showCount
              value={`${BASE_PATH}/home`}
              disabled
              prefix='https://'
              maxLength={110}
            />
          </Col>
        </Row>

        <div style={{ paddingTop: '24px' }} />

        <Collapse expandIconPosition='end'>
          {fields.map((field, index) => (
            <Collapse.Panel
              header={`Conteúdo ${index + 1}`}
              key={field.id}
              extra={
                <EmptyButton
                  onClick={() => remove(index)}
                  disabled={fields.length <= 1}
                >
                  <DeleteOutlined />
                </EmptyButton>
              }
            >
              <BaseContent
                form={form}
                fieldArrayName='modalContent'
                index={index}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Form>

      <div style={{ paddingTop: '24px' }}>
        <Row justify='center' align={'middle'}>
          <Col>
            <Button
              type='text'
              style={{ color: '#1668DC' }}
              icon={<PlusCircleOutlined />}
              onClick={() => {
                append({});
              }}
              disabled={fields.length >= 10}
            >
              Adicionar mais uma opção
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
