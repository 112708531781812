import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { FC } from 'react';

import { dateFormatted } from 'src/app/helpers/DateHelper';
import { convertToCurrency } from 'src/app/helpers/StringHelper';
import { customerAdyenReportApi } from 'src/features/clients/api/customerAdyenReportApi';
import { IAdyenRefundReportApiDto } from 'src/features/clients/types/adyenRefundApiReportDto';
import { AdyenTransaction } from 'src/features/clients/types/clientTypes';
import { customerAdyenRefundPdf } from 'src/features/clients/utils/customerAdyenReportUtils';

export type TableProps = {
  email: string;
  refundAdyen?: AdyenTransaction[];
};

export const RefundsTabsComponent: FC<TableProps> = ({
  email,
  refundAdyen,
}) => {
  const downloadReport = async (reportIdentifier?: string) => {
    if (reportIdentifier) {
      const response = await customerAdyenReportApi.adyenRefundReportData(
        reportIdentifier,
        email
      );
      customerAdyenRefundPdf((response as IAdyenRefundReportApiDto).data.data);
    }
  };

  const DropdownComponent = (item: AdyenTransaction) => {
    return (
      <Dropdown
        trigger={['click']}
        overlay={() => {
          return (
            <Menu>
              <Menu.Item
                onClick={() => downloadReport(item.reportIdentifier)}
                key='reembolso_report'
              >
                Relatório
              </Menu.Item>
            </Menu>
          );
        }}
      >
        <Button style={{ border: 'none' }}>
          <MoreOutlined />
        </Button>
      </Dropdown>
    );
  };

  return (
    <>
      <Table
        scroll={{ x: 1450 }}
        style={{ width: '100vw' }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Transação',
            dataIndex: 'gatewayTransaction',
            key: 'gatewayTransaction',
          },
          {
            title: 'Status ',
            dataIndex: 'status',
            key: 'status',
          },
          {
            title: 'Tipo',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
          },
          {
            title: 'ID da Assinatura ',
            dataIndex: 'subscriptionId',
          },
          {
            title: 'Valor',
            dataIndex: 'amount',
            render: (amount: number) => <>{convertToCurrency(amount)}</>,
          },
          {
            title: 'Data da Reembolso',
            dataIndex: 'paymentDate',
            render: (paymentDate: string) => (
              <div>{dateFormatted(paymentDate, true)}</div>
            ),
          },
          {
            title: 'Ações',
            key: 'action',
            render: (item: AdyenTransaction) => DropdownComponent(item),
          },
        ]}
        dataSource={refundAdyen}
      />
    </>
  );
};
