import { useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { SpecialistFormData } from '../../types/specialistPageTypings';
import { useTryToRequestSpecialistFunnelPage } from './useTryToRequestSpecialistFunnelPage';
import { useTryToRequestUpdateSpecialistFunnelPage } from './useTryToRequestUpdateSpecialistFunnelPage';
export function useUpdateScepialistFunnelPage(
  setValue: UseFormSetValue<{
    cancellationFunnelId: string;
    title: string;
    subtitle: string;
    buttonTitle: string;
    buttonTitleWhatsApp: string;
    email: string;
    whatsApp: string;
    interaction: boolean;
  }>
) {
  const { tryToRequestSpecialistFunnelPage } =
    useTryToRequestSpecialistFunnelPage();

  const { tryToRequestUpdateSpecialistFunnelPage, isLoading } =
    useTryToRequestUpdateSpecialistFunnelPage();

  const handleGetOneSpecialistFunnelPage = useCallback(
    async (
      pageId: string,
      setTitlePage: React.Dispatch<React.SetStateAction<string>>
    ) => {
      await tryToRequestSpecialistFunnelPage(pageId).then(response => {
        setValue('cancellationFunnelId', response.cancellationFunnelId);
        setValue('title', response.title);
        setValue('subtitle', response.subtitle);
        setValue('buttonTitle', response.buttonTitle);
        setValue('buttonTitleWhatsApp', response.buttonTitleWhatsApp);
        setValue('email', response.email);
        setValue('whatsApp', response.whatsApp);
        setValue('interaction', response.interaction);
        setTitlePage(response.titlePage);
      });
    },
    []
  );

  const handleUpdateSpecialistFunnelPage = useCallback(
    async (e: SpecialistFormData, pageId: string) => {
      await tryToRequestUpdateSpecialistFunnelPage({ id: pageId, payload: e });
    },
    []
  );

  return {
    handleGetOneSpecialistFunnelPage,
    handleUpdateSpecialistFunnelPage,
    isLoadingSpecialistPageFunnelUpdate: isLoading,
  };
}
