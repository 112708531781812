import { createCoupon, TCreateCouponProps } from '@service/api/coupons';
import { editCoupon, TEditCouponProps } from '@service/api/coupons/editCoupon';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import OpenNotification from 'src/app/components/antd/OpenNotification';
import { isSerializedApiError } from 'src/app/utils/isSerializedApiError';
import { offerApi } from 'src/features/offers/apis/offerApis';

import { TFormData } from '../types/formDataType';

export const useCouponForm = () => {
  const INFINITE_DISCOUNT_LIMIT = 10000000;

  const { isLoading: isLoadingProductOptions, data: productOptionsResult } =
    useQuery(['product-options'], () => offerApi.getProducts(), {
      onError: err => OpenNotification(true, 'Erro', handleDisplayError(err)),
      select: products => {
        return products.map(product => {
          return {
            label: product.name,
            value: Number(product.id),
          };
        });
      },
      refetchOnWindowFocus: false,
    });

  const productOptions = useMemo(() => {
    return productOptionsResult || [];
  }, [productOptionsResult]);

  const submitCreateCoupon = async (formData: TFormData) => {
    const {
      code,
      description,
      startDate,
      endDate,
      limitValue,
      typeDiscount,
      value,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;

    const payload: TCreateCouponProps = {
      code,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      discount: {
        type: typeDiscount,
        value: Number(value || '0'),
      },
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
    };
    await createCoupon(payload);
  };

  const submitEditCoupon = async (couponId: number, formData: TFormData) => {
    const {
      description,
      startDate,
      endDate,
      limitValue,
      typeDiscount,
      value,
      purchaseTypeAllowed,
      allowedProducts,
      active,
    } = formData;

    const payload: TEditCouponProps = {
      id: couponId,
      description,
      purchaseTypeAllowed,
      active,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      allowedProducts: allowedProducts || [],
      discount: {
        type: typeDiscount,
        value: Number(value || '0'),
      },
      maxUse: Number(limitValue) || INFINITE_DISCOUNT_LIMIT,
      maxUsePerCustomer: 1,
    };
    await editCoupon(payload);
  };

  const handleDisplayError = (err: unknown) => {
    console.error(err);
    if (isSerializedApiError(err))
      return err.message || `Problema de comunicação com a API`;
    return `Erro ao salvar. Contate a equipe técnica.`;
  };

  return {
    INFINITE_DISCOUNT_LIMIT,
    productOptions,
    isLoadingProductOptions,
    submitCreateCoupon,
    submitEditCoupon,
    handleDisplayError,
  };
};
