import { guestApi } from 'src/app/guestApi';

import {
  AdyenAssignResponse,
  AdyenCancellationResponse,
  AdyenTransactionResponse,
  ClassViewedResponseDto,
  ClientDTOType,
  CommentTypeResponse,
  CustomerAdyenRefundApiResponseDto,
  CustomerDashboardEmailsResponse,
  CustomerDashboardIndicationsResponse,
  HotmartSubscriptionApiResponse,
  HotmartTransactionApiResponse,
  ProfileDTOType,
  ProfilesResponse,
  ProgramAccessLastResponseDto,
  ProgramAccessResponseDto,
  ProgramFavoriteResponseDto,
  ResponseClient,
  ResponseClientByIdOrEmail,
  ScheduledCancellationDto,
  ScheduledCancellationResponse,
  SubscriptionsPagarMeResponse,
  TransactionItunesResponse,
  TransactionPagarMeResponse,
} from '../types/clientTypes';

async function listCustomers(
  params: string
): Promise<ResponseClient | unknown> {
  try {
    const endpoint = `/customer` + params;
    return guestApi.get(endpoint);
  } catch (e) {
    return {
      status: 500,
      msg: (e as Error).message,
    };
  }
}

async function listCustomersByIdOrEmail(
  params?: string
): Promise<ResponseClient> {
  try {
    const endpoint = `/customer/${params}`;
    const res = await guestApi.get(endpoint);

    if (res && res.data) {
      res.data.profileOnboarding[0].birthday = res.data.profileOnboarding[0]
        .birthday
        ? new Date(res.data.profileOnboarding[0].birthday)
        : undefined;

      res.data.users[0].email = res.data?.users[0].email
        ? res.data?.users[0].email
        : '';
    }

    return res;
  } catch (error) {
    throw new Error('Something bad happened');
  }
}

async function getCustomerByIdOrEmail(
  id?: string
): Promise<ResponseClientByIdOrEmail | unknown> {
  try {
    const endpoint = `/customer/${id}`;
    const res = await guestApi.get(endpoint);
    if (res.data && res.data.profile) {
      res.data.profile.birthday = new Date(res.data.profile.birthday);
    }

    return res;
  } catch (error) {
    return error;
  }
}

async function updateUser(param: ClientDTOType): Promise<ResponseClient> {
  const endpoint = `/customer/` + param.id;
  return guestApi.put(endpoint, {
    email: param.email,
    document: param.document,
    name: param.name,
    phone: param.phone,
  });
}
async function updateProfile(param: ProfileDTOType): Promise<ResponseClient> {
  const endpoint = `/customer/${param.customerId}/profile`;
  return guestApi.put(endpoint, param);
}

async function listSubscriptionsPagarme(
  param?: string
): Promise<SubscriptionsPagarMeResponse | unknown> {
  if (param === undefined) return null;
  try {
    const endpoint = `/customer/pagarme?page=1&limit=20&email=${param}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function listTransactionsPagarme(
  param?: string
): Promise<TransactionPagarMeResponse | unknown> {
  if (param === undefined) return null;
  try {
    const endpoint = `/customer/pagarme/transaction?page=1&limit=20&email=${param}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function changePassword(param: {
  password: string;
  id?: string;
}): Promise<ProfilesResponse | unknown> {
  try {
    const endpoint = `/customer/${param.id}/password`;
    return await guestApi.post(endpoint, {
      password: param.password,
    });
  } catch (error) {
    return error;
  }
}

async function libertAccess(param: {
  legacyId?: number;
  dayExpirationDate?: number;
  email?: string;
  id?: string;
}): Promise<ProfilesResponse | unknown> {
  try {
    const endpoint = `/customer/${param.id}/release-access`;
    return await guestApi.post(endpoint, {
      email: param.email,
      legacyId: param.legacyId,
      dayExpirationDate: param.dayExpirationDate,
    });
  } catch (error) {
    return error;
  }
}

async function sendEmailFirstAccess(param: {
  id?: string;
  email?: string;
}): Promise<ProfilesResponse | unknown> {
  try {
    const endpoint = `/customer/send-email`;
    return await guestApi.post(endpoint, {
      email: param.email,
    });
  } catch (error) {
    return error;
  }
}

async function revokeAccess(id?: string): Promise<ProfilesResponse | unknown> {
  try {
    const endpoint = `/customer/${id}`;
    return await guestApi.delete(endpoint);
  } catch (error) {
    return error;
  }
}

async function getHotmartTableAssign(
  email?: string
): Promise<HotmartSubscriptionApiResponse | unknown> {
  try {
    if (!email) {
      return {
        data: {
          subscriptions: [],
        },
        status: 200,
      };
    }
    const endpoint = `/customer/hotmart/subscription?email=${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getHotmartTableTransactions(
  email?: string
): Promise<HotmartTransactionApiResponse | unknown> {
  try {
    if (!email) {
      return {
        data: {
          transactions: [],
        },
        status: 200,
      };
    }
    const endpoint = `/customer/hotmart/transaction?email=${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getAdyenTableAssignByCustomerId(
  customerId?: number
): Promise<AdyenAssignResponse | unknown> {
  try {
    const endpoint = `/customer/Adyen/subscription/${customerId}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getAdyenTableAssignByEmail(
  email?: string
): Promise<AdyenAssignResponse | unknown> {
  try {
    const endpoint = `/customer/Adyen/subscription/email/${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getAdyenTableCancelation(
  email?: string
): Promise<AdyenTransactionResponse | unknown> {
  try {
    const endpoint = `/customer/adyen/refund/refused/${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getAdyenTableTransactions(
  email?: string
): Promise<AdyenTransactionResponse | unknown> {
  try {
    const endpoint = `/customer/adyen/transaction/${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getItunesTableTransactions(
  email?: string
): Promise<TransactionItunesResponse | unknown> {
  try {
    const endpoint = `/customer/itunes?email=${email}`;
    return await guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getComments(
  id?: string
): Promise<CommentTypeResponse | unknown> {
  try {
    const endpoint = `/customer/comments/${id}`;
    return guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function postComments(param: {
  annotation?: string;
  userId?: string;
}): Promise<CommentTypeResponse | unknown> {
  try {
    const endpoint = `/customer/comments`;
    return guestApi.post(endpoint, param);
  } catch (error) {
    return error;
  }
}

async function deleteComments(
  id?: number
): Promise<CommentTypeResponse | unknown> {
  try {
    const endpoint = `/customer/comments/${id}`;
    return guestApi.delete(endpoint);
  } catch (error) {
    return error;
  }
}

async function getCustomerDashboardEmailsById(
  id?: string,
  params?: string
): Promise<CustomerDashboardEmailsResponse | unknown> {
  try {
    const endpoint = `/customer/${id}/dashboard` + params;
    return guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function getCustomerDashboardIndicationsById(
  id?: string,
  params?: string
): Promise<CustomerDashboardIndicationsResponse | unknown> {
  try {
    const endpoint = `/customer/${id}/dashboard/indications` + params;
    return guestApi.get(endpoint);
  } catch (error) {
    return error;
  }
}

async function adyenRefund(param: {
  gatewayTransaction: string;
  amount: number;
  billingId: number;
  document: string;
}): Promise<CustomerAdyenRefundApiResponseDto | unknown> {
  try {
    return guestApi.post(`/customer/adyen/refund`, param);
  } catch (error) {
    return error;
  }
}

async function adyenCancellation(
  subscriptionId: number
): Promise<AdyenCancellationResponse | unknown> {
  try {
    return guestApi.put(`/customer/adyen/cancellation/${subscriptionId}`);
  } catch (error) {
    return error;
  }
}

async function scheduledCancellation(
  params: ScheduledCancellationDto
): Promise<ScheduledCancellationResponse | unknown> {
  try {
    return guestApi.post(`/scheduled/task/cancellation/`, params);
  } catch (error) {
    return error;
  }
}

async function getClassViewed(
  emailClassViewed?: string
): Promise<ClassViewedResponseDto | unknown> {
  try {
    return guestApi.get(
      `/customer/usage/data/class/viewed?email=${emailClassViewed}`
    );
  } catch (error) {
    return error;
  }
}

async function getProgramFavorite(
  emailProgramFavorite?: string
): Promise<ProgramFavoriteResponseDto | unknown> {
  try {
    return guestApi.get(
      `/customer/usage/data/program/favorite?email=${emailProgramFavorite}`
    );
  } catch (error) {
    return error;
  }
}

async function getProgramAccessLast(
  emailProgramAccessLast?: string
): Promise<ProgramAccessLastResponseDto | unknown> {
  try {
    return guestApi.get(
      `/customer/usage/data/program/access/last?email=${emailProgramAccessLast}`
    );
  } catch (error) {
    return error;
  }
}

async function getProgramAccess(
  emailProgramAccess?: string
): Promise<ProgramAccessResponseDto | unknown> {
  try {
    return guestApi.get(
      `/customer/usage/data/platform/access?email=${emailProgramAccess}`
    );
  } catch (error) {
    return error;
  }
}

async function getClassAttended(
  emailAttended?: string
): Promise<ProgramAccessLastResponseDto | unknown> {
  try {
    return guestApi.get(
      `/customer/usage/data/class/attended?email=${emailAttended}`
    );
  } catch (error) {
    return error;
  }
}

export const clientApi = {
  getClassAttended,
  getProgramAccess,
  adyenCancellation,
  scheduledCancellation,
  adyenRefund,
  listCustomers,
  getCustomerByIdOrEmail,
  updateProfile,
  updateUser,
  listSubscriptionsPagarme,
  listTransactionsPagarme,
  listCustomersByIdOrEmail,
  changePassword,
  libertAccess,
  sendEmailFirstAccess,
  revokeAccess,
  getHotmartTableAssign,
  getHotmartTableTransactions,
  getComments,
  postComments,
  deleteComments,
  getCustomerDashboardEmailsById,
  getCustomerDashboardIndicationsById,
  getAdyenTableAssignByEmail,
  getAdyenTableAssignByCustomerId,
  getAdyenTableTransactions,
  getItunesTableTransactions,
  getAdyenTableCancelation,
  getClassViewed,
  getProgramFavorite,
  getProgramAccessLast,
};
