import { PageHeader } from '@organisms';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';

import { ICoupon } from 'src/models/Coupon.model';

import { CouponForm } from './components/CouponForm/CouponForm.component';
import { useCoupon } from './hooks/useCoupon';

export function ReadCouponPage() {
  const { state }: { state: { coupon: ICoupon } } = useLocation();
  const { formRef, isLoading, setIsLoading } = useCoupon();

  return (
    <Spin spinning={isLoading}>
      <PageHeader
        backToPath={() => {
          window.history.back();
        }}
        title={`Visualização do Cupom ${state.coupon.code}`}
        subTitle={'Desconto de um valor específico. Exemplo: R$10,00 OFF.'}
      />

      <CouponForm
        formRef={formRef}
        onLoading={setIsLoading}
        coupon={state.coupon}
        readonly
      />
    </Spin>
  );
}
